import { Component, OnInit } from '@angular/core';
import GastoRutaAnual from 'src/app/shared/models/GastoRutaAnual';
import { diasutilidad } from 'src/app/shared/services/diasutilidad.service'; 
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import User from 'src/app/shared/models/user';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
  selector: 'app-importe-de-gastos-de-ruta-anual',
  templateUrl: './importe-de-gastos-de-ruta-anual.component.html',
  styleUrls: ['./importe-de-gastos-de-ruta-anual.component.scss']
})
export class ImporteDeGastosDeRutaAnualComponent {
  imprutaanual: GastoRutaAnual[] = [];
  usuario:User={};
  fecha_dia:any;
  numero_operacion:number=0;
  color_gris_deshabilitadas:string = '#D9D9D9';
  dspimpcombustible:boolean=false;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  loading: boolean = true;

  impCombustibleForm: FormGroup = new FormGroup({
    idx: new FormControl('', [Validators.required]),
    des_tipodecombustible: new FormControl('', [Validators.required]),
    imp_combustible: new FormControl(0, []),
});


  constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private diasutilidad:diasutilidad){
    this.cargarInformacionUsuario();
  }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  ngOnInit(): void {
    this.inicializarFechas();
    this.GetGastosRuta();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
  }

  GetGastosRuta(): void {
    this.loading = true; 
    this.diasutilidad.getgastosanual().subscribe({
        next: (data) => {
            this.imprutaanual = data; 
            setTimeout(() => {
                this.loading = false; 
            }, 1000);
        },
        error: (err) => {
            console.error('Error al cargar los datos de base de cobros', err);
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    });
}

  cancelarVentanaModal() {
    this.texto_operacion = '';
    this.dspimpcombustible = false;
    this.impCombustibleForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }
}

