import { Component, OnInit } from '@angular/core';
import ImporteCombustible from 'src/app/shared/models/ImporteCombustible';
import { impcombustible } from 'src/app/shared/services/impcombustible.service'; 
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import User from 'src/app/shared/models/user';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-importe-de-diesel',
  templateUrl: './importe-de-diesel.component.html',
  styleUrls: ['./importe-de-diesel.component.scss']
})
export class ImporteDeDieselComponent implements OnInit {
  impcombustible: ImporteCombustible[] = [];
  usuario:User={};
  fecha_dia:any;
  numero_operacion:number=0;
  color_gris_deshabilitadas:string = '#D9D9D9';
  dspimpcombustible:boolean=false;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  loading: boolean = true;

  impCombustibleForm: FormGroup = new FormGroup({
    idx: new FormControl('', [Validators.required]),
    des_tipodecombustible: new FormControl('', [Validators.required]),
    imp_combustible: new FormControl(0, []),
});

data_editar_impcombustible: ImporteCombustible={
  opcion:0,
  idx:0,
  cod_usuario:'',
  imp_combustible:0,
  des_tipodecombustible:'',
};


  constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private impcombustibleservice:impcombustible){
    this.cargarInformacionUsuario();
  }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  ngOnInit(): void {
    this.inicializarFechas();
    this.GetImporteCombustible();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
  }

  GetImporteCombustible(): void {
    this.loading = true; //
    this.impcombustibleservice.getimpcombustible().subscribe({
        next: (data) => {
            this.impcombustible = data; 
            setTimeout(() => {
                this.loading = false; 
            }, 1000);
        },
        error: (err) => {
            console.error('Error al cargar los datos de base de cobros', err);
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    });
}

 
  mostrarModalEditar(data: ImporteCombustible, num_operacion: number) {
    this.dspimpcombustible = true; 
    this.data_editar_impcombustible = data; 
    this.numero_operacion = num_operacion;
    
    // Llenar el formulario con los datos a editar.
    this.impCombustibleForm.patchValue({
        idx: data.idx,
        imp_combustible: data.imp_combustible,
    });

    // Cambiar el texto de la operación.
    this.texto_operacion = ' Editar Combustible: ' + data.des_tipodecombustible ;
}
guardarInformacion() {
  this.inicializarFechas();
  const data_enviar: ImporteCombustible = {
      idx: this.impCombustibleForm.get('idx')?.value,
      des_tipodecombustible: '',
      imp_combustible: this.impCombustibleForm.get('imp_combustible')?.value,
      cod_usuario: this.usuario.cod,
      opcion: this.numero_operacion,
  };


  this.impcombustibleservice.editimpcombustible(data_enviar).subscribe((resp) => {
      const resultado = resp;
      if (resultado.clv_estatus == 1) {
          setTimeout(() => {
              this.GetImporteCombustible(); 
              this.message.add({ severity: 'success', summary: 'Operación Exitosa', detail: 'La información se almacenó correctamente' });
              this.impCombustibleForm.reset();
              this.cancelarVentanaModal();
          }, 800);
      }
  }, (error) => {
      this.message.add({ severity: 'error', summary: 'Error en <Guardar Base de Cobros>', detail: 'Contacte al Administrador del Sitio' });
  });
}

  cancelarVentanaModal() {
    this.texto_operacion = '';
    this.dspimpcombustible = false;
    this.impCombustibleForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }
}
