<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '25vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none;">
                        <div class="cardicono rounded">
                            <i class="pi pi-car mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 6%; top: 0%;">Importe Combustible Sodexo</header>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="impsodexo" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped"
                                dataKey="id_config_unidad" [rows]="15" 
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [resizableColumns]="false"
                                [paginator]="true" responsiveLayout="scroll" 
                                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                                [globalFilterFields]="['id_config_unidad', 'des_tipoconfiguracionunidad', 'km_inicial', 'km_final', 'imp_sueldodriver', 'imp_sueldoaux', 'imp_prestacionesrhdiario', 'imp_mantenimientodiario', 'imp_monitoreodiario', 'imp_operaciones', 'imp_depreciacion', 'clv_activo']">
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th>#</th>
                                        <th style="min-width: 10rem">Número de porcentaje</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex">
                                    <tr>
                                        <td>
                                            <div class="texto_negrita">{{(index + 1)}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">
                                                <i class="pi pi-car" style="margin-right: 5px;"></i>{{data.num_porcentaje}}
                                            </div>
                                        </td>    
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="13">No se encontró ningún registro.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>