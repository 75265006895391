/*export const environment = {
    production: false,
    SUrlgApi: "https://localhost:44313/api/v1/",
    sUrlAuth: "http://localhost:5286/api/"
};*/

export const environment = {
    production: false,
    SUrlgApiLocal: "https://localhost:44313/api/v1/",
    sUrlAuthLocal: "http://localhost:5286/api/",
    SUrlgApi: "https://beta.apicomercial.sw-hmp.com/api/v1/",
    sUrlAuth: "https://beta.apiauth.sw-hmp.com/api/"
};

