<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '25vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none;">
                        <div class="cardicono rounded">
                            <i class="pi pi-calculator mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 6%; top: 0%;">Base De Cobros</header>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="basedecobros" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped"
                                dataKey="id_config_unidad" [rows]="50" 
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [resizableColumns]="false"
                                [paginator]="true" responsiveLayout="scroll" 
                                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                                [globalFilterFields]="['id_config_unidad', 'des_tipoconfiguracionunidad', 'km_inicial', 'km_final', 'imp_sueldodriver', 'imp_sueldoaux', 'imp_prestacionesrhdiario', 'imp_mantenimientodiario', 'imp_monitoreodiario', 'imp_operaciones', 'imp_depreciacion', 'clv_activo']">
                                <ng-template pTemplate="caption" let-data>
                                    <div class="flex">
                                        <button pButton label="Exportar Todo a Excel"
                                            class="p-button-outlined qolor ml-auto p-button-raised" icon="pi pi-file-excel"
                                            pTooltip="Haz clic para exportar todos los datos a Excel"
                                            style="color: #ffffff; background-color: rgb(62, 139, 86)"
                                            (click)="GenerarTodoAExcel()">
                                        </button>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th>#</th>
                                        <th style="min-width: 10rem">ID Config. Unidad</th>
                                        <th style="min-width: 15rem">Tipo Configuración</th>
                                        <th style="min-width: 10rem">KM Inicial</th>
                                        <th style="min-width: 10rem">KM Final</th>
                                        <th style="min-width: 10rem">Sueldo Driver</th>
                                        <th style="min-width: 10rem">Sueldo Aux.</th>
                                        <th style="min-width: 10rem">Prestaciones RH Diario</th>
                                        <th style="min-width: 10rem">Mantenimiento Diario</th>
                                        <th style="min-width: 10rem">Monitoreo Diario</th>
                                        <th style="min-width: 10rem">Operaciones</th>
                                        <th style="min-width: 10rem">Depreciación</th>
                                        <th style="min-width: 10rem">Estatus</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex">
                                    <tr [ngClass]="{'row-accessories': data.clv_activo === 0}">
                                        <td>
                                            <div class="texto_negrita">{{(index + 1)}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.id_config_unidad}}</div>
                                        </td>
                                        <td class="texto_negrita texto_naranja_ba">
                                            <div class="texto_negrita"> 
                                                <i class="fa-solid fa-van-shuttle"></i>{{data.des_tipoconfiguracionunidad}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita"> 
                                                <i class="fa-solid fa-gauge"></i>{{data.km_inicial}}
                                            </div>
                                        </td> 
                                        <td>
                                            <div class="texto_negrita"> 
                                                <i class="fa-solid fa-gauge"></i>{{data.km_final}}
                                            </div>
                                        </td>        
                                        <td>
                                            <div class="texto_negrita">{{data.imp_sueldodriver| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_sueldoaux| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_prestacionesrhdiario| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_mantenimientodiario| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_monitoreodiario| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_operaciones| currency}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita">{{data.imp_depreciacion| currency}}</div>
                                        </td>
                                        <td>
                                            <p-tag *ngIf="data.clv_activo == 1" icon="pi pi-user" [rounded]="true" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo == 0" icon="pi pi-user" [rounded]="true" severity="warning" value="Desactivado"></p-tag>
                                        </td>
                                        <td style="width: 130px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion"
                                                     tooltipPosition="left" (click)="mostrarModalEditar(data,1);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus"
                                                         tooltipPosition="top" (click)="confirmarDesactivar(data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="13">No se encontró ningún registro.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="dspbasedecobros" [resizable]="false" [draggable]="false" [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3>{{ texto_operacion }}</h3> 
        </ng-template>
        <ng-template pTemplate="content">
            <form [formGroup]="baseDeCobrosForm" (ngSubmit)="guardarInformacion()">
                <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="km_inicial">KM Inicial</label>
                        <input type="number" id="km_inicial" formControlName="km_inicial" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="km_final">KM Final</label>
                        <input type="number" id="km_final" formControlName="km_final" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_sueldodriver">Sueldo Driver</label>
                        <input type="number" id="imp_sueldodriver" formControlName="imp_sueldodriver" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_sueldoaux">Sueldo Aux.</label>
                        <input type="number" id="imp_sueldoaux" formControlName="imp_sueldoaux" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_prestacionesrhdiario">Prestaciones RH Diario</label>
                        <input type="number" id="imp_prestacionesrhdiario" formControlName="imp_prestacionesrhdiario" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_mantenimientodiario">Mantenimiento Diario</label>
                        <input type="number" id="imp_mantenimientodiario" formControlName="imp_mantenimientodiario" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_monitoreodiario">Monitoreo Diario</label>
                        <input type="number" id="imp_monitoreodiario" formControlName="imp_monitoreodiario" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_operaciones">Operaciones</label>
                        <input type="number" id="imp_operaciones" formControlName="imp_operaciones" class="form-control">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="imp_depreciacion">Depreciación</label>
                        <input type="number" id="imp_depreciacion" formControlName="imp_depreciacion" class="form-control">
                    </div>
                </div>
            </form>
        </ng-template>
        <ng-template pTemplate="footer">
            <div style="display: flex; justify-content: flex-end; gap: 10px;">
                <button pButton label="Guardar" icon="pi pi-save" (click)="guardarInformacion()"></button>
                <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancelarVentanaModal()"></button>
            </div>
        </ng-template>
    </p-dialog>
</div>