<p-toast position="bottom-center" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 100%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded mt-5">
                            <i class="pi pi-calculator mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 11%; top: 0%;">Tabulador de Costos Cliente: <b
                                class="texto_verde_oliva">{{this.NombreEmpresaGlobal}}</b></header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button class="btn color w-100 p-button-raised" (click)="Regresar()" pTooltip="Regresar"
                                    tooltipPosition="top">
                                    Regresar a Clientes Prospecto<i class="pi pi-arrow-circle-left"></i>
                                </button>
                                <button class="btn btn-primary w-100 mt-2 p-button-raised" (click)="Tabular()"
                                    pTooltip="Agregar nuevo Tabulado" tooltipPosition="top">
                                    Agregar Nuevo Tabulado<i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3 mx-0 px-1">
                            <p-table #dt1 [value]="TabuladorCostos" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" dataKey="id" responsiveLayout="scroll"
                                [scrollable]="true" scrollDirection="both" scrollHeight="580px" [paginator]="true"
                                [rows]="6" [rowsPerPageOptions]="[6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 100]" [loading]="loading"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                [globalFilterFields]="['config_unidad']">
                                <ng-template pTemplate="caption" let-data>
                                    <div class="flex">
                                        <button pButton label="Limpiar" class="p-button-outlined mr-2"
                                            icon="pi pi-filter-slash" (click)="clear(dt1)">
                                        </button>
                                        <p-columnFilter class="ml-4" field="clv_aceptado" matchMode="equals"
                                            [showMenu]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="estatusPropuesta"
                                                    optionLabel="label" optionValue="value"
                                                    (onChange)="filter($event.value)" placeholder="Filtrar por estatus"
                                                    [showClear]="true">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                        <button pButton label="Exportar Todo a Excel"
                                            class="p-button-outlined qolor ml-3 p-button-raised" icon="pi pi-file-excel"
                                            style="color: #ffffff; background-color: rgb(62, 139, 86)"
                                            (click)="GenerarTodoAExcel()">
                                        </button>
                                        <span class="p-input-icon-left ml-auto">
                                            <input pInputText type="text"
                                                (input)="applyFilterGlobal($event, 'contains')"
                                                placeholder="Buscar Configuracion de unidad" size="40" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                                            #
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Corrida No.
                                                <p-columnFilter type="text" field="id_corrida"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Origen
                                                <p-columnFilter type="text" field="origen"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-letf">
                                                Destino
                                                <p-columnFilter type="text" field="destino"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:8rem;width: 8rem;">
                                            <div class="flex align-items-center">
                                                Numero de Dias
                                            </div>
                                        </th>
                                        <th pFrozenColumn style="min-width:9rem;width: 9rem;">
                                            <div class="flex align-items-center">
                                                Numero de Kilometros
                                            </div>
                                        </th>
                                        <th style="min-width:8rem;width: 8rem;">
                                            <div class="flex align-items-center">
                                                Peaje
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Configuracion
                                                <p-sortIcon field="configuracion"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th style="min-width:14rem;width: 14rem;">
                                            <div class="flex align-items-center">
                                                Costo
                                                <p-columnFilter type="text" field="costo"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Precio de Venta
                                                <p-columnFilter type="text" field="imp_preciodeventa"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Utilidad
                                                <p-columnFilter type="text" field="imp_utilidad"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Comision a Terceros
                                            </div>
                                        </th>

                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Numero de Choferes
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Numero de Auxiliares
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Sueldo Chofer
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Sueldo Auxiliar
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Importe Combusible
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Comisión Combusible
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Urea
                                            </div>
                                        </th>
                                        <th *ngIf="shouldShowHospedaje" style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Hospedaje
                                            </div>
                                        </th>
                                        <th *ngIf="shouldShowHospedaje" style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Viáticos
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                IMSS
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Seguridad Patrimonial
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Operaciones
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Depreciacion
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Mantenimiento
                                            </div>
                                        </th>
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Monitoreo
                                            </div>
                                        </th>
                                        <!--th style="min-width: 10rem; width: 10rem;">
                                            <div class="flex align-items-center">
                                                Estatus Propuesta
                                            </div>
                                        </!--th-->
                                        <th style="min-width:10rem;width: 10rem;">
                                            <div class="flex align-items-center">
                                                Estatus Final
                                            </div>
                                        </th>
                                        <th style="min-width:4rem;width: 4rem;">
                                            <div class="flex align-items-center">
                                                Tabulador
                                            </div>
                                        </th>
                                        <th style="min-width: 14rem;width: 14rem;">

                                        </th>
                                        <th style="min-width: 14rem;width: 14rem;">

                                        </th>
                                        <th style="min-width: 14rem;width: 14rem;">

                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex" let-editing="editing">
                                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}"
                                        style="font-size: 1.2rem;">
                                        <td pFrozenColumn [frozen]="true"  class="texto_negrita" style="min-width:5rem;width: 5rem;">
                                            {{(index+1)}}
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita" style="min-width:10rem;width: 10rem;">
                                            <div><i class="fa-solid fa-hashtag"></i> {{data.id_corrida}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita texto_verde_oliva" style="min-width:10rem;width: 10rem;">
                                            <div><i class="fa-solid fa-map-location-dot"></i>{{data.des_origen}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita texto_verde_oliva"style="min-width:10rem;width: 10rem;">
                                            <div><i class="fa-solid fa-map-location-dot"></i>{{data.des_destino}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true" class="texto_negrita" style="min-width:8rem;width: 8rem;">
                                            <div class="texto_negrita"><i class="fa-regular fa-calendar-days color-icon"
                                                    style="font-size: 1.3rem;"></i> {{data.num_dias}}</div>
                                        </td>
                                        <td pFrozenColumn [frozen]="true" style="min-width:9rem;width: 9rem;">
                                            <div class="texto_negrita"><i class="fa-solid fa-gauge"
                                                    style="font-size: 1.3rem;"></i> {{data.num_kilometros}}</div>
                                        </td>
                                        <td style="min-width:8rem;width: 8rem;">
                                            <div class="texto_negrita"> {{data.imp_peaje|currency}}</div>
                                        </td>
                                        <td class="texto_negrita texto_naranja_ba"style="min-width:10rem;width: 10rem;">
                                            <div><i class="fa-solid fa-van-shuttle"></i>{{data.config_unidad}}</div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita"style="min-width:14rem;width: 14rem;">{{data.costo| currency}} &nbsp;<i
                                                    class="fa-solid fa-chart-line char"
                                                    style="color: rgb(115, 107, 186); font-size: 1.6rem; padding-top: 4px;"
                                                    pTooltip="Ver Grafica del Costo" (click)="VerGrafica(data)"></i>
                                            </div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita colo">{{data.imp_preciodeventa| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita">{{data.imp_utilidad| currency}} <i
                                                    style="color:#2454B8 !important;font-size: 1.4rem;"
                                                    class="fa-solid fa-circle-info color-icon"
                                                    pTooltip="El Porcentaje de Utilidad es {{data.num_porcentajeutd}}%"></i>
                                            </div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita">{{data.imp_comision_tercero| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"><i class="fa-regular fa-user"
                                                    style="font-size: 1.3rem;"></i> {{data.num_choferes}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"><i class="fa-regular fa-user"
                                                    style="font-size: 1.3rem;"></i> {{data.num_auxiliares}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita">{{data.imp_sueldochofer| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_sueldoaux| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_combustible| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_comisioncombustible| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_urea| currency}}
                                            </div>
                                        </td>
                                        <td *ngIf="data.num_dias>0" style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"><i class="fa-solid fa-house-user"
                                                    style="font-size: 1.3rem;"></i> {{data.imp_hospedaje| currency}}
                                            </div>
                                        </td>
                                        <td *ngIf="data.num_dias>0" style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"><i class="fa-solid fa-money-bill"
                                                    style="font-size: 1.3rem;"></i> {{data.imp_viaticos| currency}}
                                            </div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_imss| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_seguridadpatrimonial| currency}}
                                            </div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_operaciones| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita">{{data.imp_depreciacion|currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_mantenimiento| currency}}</div>
                                        </td>
                                        <td style="min-width:10rem;width: 10rem;">
                                            <div class="texto_negrita"> {{data.imp_cel_gps| currency}}</div>
                                        </td>
                                        <td [pEditableColumn]="data.clv_enviado" pEditableColumnField="clv_enviado" >
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <label class="switch" pTooltip="{{ data.clv_enviado === 1 ? 'Enviado' : 'No Enviado' }}" tooltipPosition="top">
                                                        <input type="checkbox" 
                                                               [(ngModel)]="data.clv_enviado" 
                                                               (change)="onEstatusPropuestaChange($event, data)" 
                                                               [disabled]="data.clv_enviado === 1" 
                                                               aria-label="Cambiar estado">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <label class="switch" *ngIf="data.clv_enviado === 0" pTooltip="No Enviado" tooltipPosition="top">
                                                        <input type="checkbox" [checked]="false" (change)="onEstatusPropuestaChange($event, data)" aria-label="Estatus enviado">
                                                        <span class="slider round"></span>
                                                    </label>  
                                                    <label class="switch" *ngIf="data.clv_enviado === 1" pTooltip="Enviado" tooltipPosition="top">
                                                        <input type="checkbox" [checked]="true" disabled aria-label="Estatus ya enviado">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td> 
                                        <td style="text-align: center;">
                                            <label 
                                                class="pi pi-info-circle" 
                                                style="font-size: 30px; color: #c08d2f"
                                                pTooltip="Haz click para ver información"
                                                (click)="verTabuladorDiario(data.idx)">
                                            </label>
                                        </td>                           
                                        <td>
                                            <button pButton label="Generar Cotizacion" class="btn btn-primary"
                                                icon="fa-solid fa-folder-plus" (click)="VerFrmCotizacion(data)">
                                            </button>
                                        </td>
                                        <td>
                                            <button *ngIf="data.clv_enviado===0" pButton style="background-color: rgb(154, 154, 5);" label="Editar Tabulado" class="btn btn-secondary"
                                                icon="pi pi-file-edit" (click)="EditarTabulado(data)">
                                            </button>
                                        </td>
                                        <td>
                                            <button pButton label="Exportar a Excel" class="p-button-outlined qolor"
                                                icon="pi pi-file-excel"
                                                style="color: #ffffff; background-color: rgb(62, 139, 86)"
                                                (click)="GenerarExcel(data)">
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">Sin Tabulados.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="dspTabuladorDiario" [modal]="true" [responsive]="true" [style]="{ width: '60vw' }" [draggable]="false">
        <ng-template pTemplate="header">
            Información del tabulador
        </ng-template>
        <div class="tabulador-diario-detalle">
            <h6 class="negrita-azul">Detalle de la Ruta</h6>
            <p-table [value]="tabuladorDiarioData" responsiveLayout="scroll" [tableStyle]="{'min-width': '30rem'}" styleClass="p-datatable-sm p-datatable-striped tabulador-diario-table">
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td class="negrita">Ruta</td>
                        <td class="negrita-azul">{{item.des_origen}} | {{item.des_destino}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Días De Ruta</td>
                        <td class="negrita-naranja">{{item.num_dias}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Número De Choferes</td>
                        <td>{{item.num_choferes}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Número De Auxiliares</td>
                        <td>{{item.num_auxiliares}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Sueldo Chofer</td>
                        <td>{{item.imp_individual_chofer | currency}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Sueldo Auxiliar</td>
                        <td>{{item.imp_individual_aux | currency}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Alimentación Chofer</td>
                        <td>{{item.imp_alimento_chofer | currency}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Alimentación Auxiliar</td>
                        <td>{{item.imp_alimento_aux | currency}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Días Hospedaje</td>
                        <td class="negrita-naranja">{{item.num_dias_hospedaje}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Estancia Chofer</td>
                        <td>{{item.imp_estancia_chofer | currency}}</td>
                    </tr>
                    <tr>
                        <td class="negrita">Importe Estancia Auxiliar</td>
                        <td>{{item.imp_estancia_aux | currency}}</td>
                    </tr>
                </ng-template>
            </p-table>
            <p class="letra-cursiva-pequena">Nota:</p>
            <p class="letra-cursiva-pequena">*El importe es el sueldo diario, alimento diario y se multiplica por el número de días de Ruta para el sueldo completo del Colaborador.</p>
            <p class="letra-cursiva-pequena">*El importe de Hospedaje diario se multiplica por el numero de días de hospedaje.</p>
        </div>
        <p-footer>
            <button pButton type="button" label="Cerrar" icon="pi pi-times" class="p-button-text" (click)="cancelarVentanaModal()"></button>
        </p-footer>
    </p-dialog>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspTabulador" [modal]="true" (onHide)="cancelarVentanaModal()"
        [style]="{width: '90vw','height':'1150px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
                
        </ng-template>
        <ng-template pTemplate="content">
            <div style="display: flex;" *ngIf="dspTablaRegreso===false">
                <div style="width: 65%; padding-right: 10px;">
                    <div class="mt-5">
                        <div class="card-content card-shadow sinborde">
                            <form class="form_cliente" [formGroup]="tabuladorForm">
                                <div class="ml-2 px-2">
                                    <span class="title-light" style="font-size:1.3rem;">Contenido del Tabulador<i
                                            class="pi pi-cog" style="size: 2.2rem;"></i></span>
                                            <div class="row mt-5 form-card">
                                                <span style="color: #2454B8;">
                                                    <h2><i class="pi pi-wallet" style="font-size: 2rem;"></i> Informacion Base
                                                        Para el Cliente:</h2>
                                                </span>
                                            </div>
                                            <div class="row mt-5">
                                                <div class="form-group col-md-4">
                                                    <div>
                                                        <span class="texto_negrita">Seleccionar el Tipo de Ruta:* </span>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <div class="row mt-2 ml-2">
                                                        <div class="col-sm-6 pl-2">
                                                            <p-dropdown [options]="cat_Rutas"
                                                                placeholder="Selecciona el Tipo de Ruta" autoWidth="false"
                                                                [(ngModel)]="idruta" optionLabel="nom_area"
                                                                optionValue="id_area" formControlName="Area" [showClear]="true"
                                                                [appendTo]="'body'"></p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <div class="row g-0">
                                                        <div class="col-sm-3 mx-0 d-flex align-items-center">
                                                            <span class="input-group-addon"> <i
                                                                    class="fa-solid fa-flag-usa color-icon"
                                                                    style="font-size: 2rem"></i>
                                                            </span>
                                                            <p class="color-icon mt-2 ml-1"
                                                                style="display: inline-block; font-weight: bold;">Origen</p>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <p-dropdown *ngIf="idruta==1" [options]="municipioscat"
                                                                optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                                [showClear]="true" formControlName="origen"
                                                                placeholder="Seleccione el Origen" autoWidth="false"
                                                                (onChange)="getFiltradoMunicipios($event)"
                                                                [style]="{'width':'100%'}" optionValue="id"></p-dropdown>
                                                            <p-dropdown *ngIf="idruta==2" [options]="municipioscat"
                                                                optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                                [showClear]="true" formControlName="origen"
                                                                placeholder="Seleccione el Origen" autoWidth="false"
                                                                [style]="{'width':'100%'}" optionValue="id"></p-dropdown>
                                                            <hr class="my-0">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <div class="row g-0">
                                                        <div class="col-sm-4 mx-0 d-flex align-items-center">
                                                            <span class="input-group-addon"> <i
                                                                    class="fa-solid fa-city color-icon"
                                                                    style="font-size: 2rem"></i>
                                                            </span>
                                                            <p class="color-icon mt-2 mr-1"
                                                                style="display: inline-block; font-weight: bold;">Destino</p>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <p-dropdown *ngIf="idruta==1" [options]="MunicipiosFiltrados"
                                                                optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                                formControlName="destino" placeholder="Seleccione el Destino"
                                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                                [showClear]="true"></p-dropdown>
                                                            <p-dropdown *ngIf="idruta==2" [options]="municipioscat"
                                                                optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                                formControlName="destino" placeholder="Seleccione el Destino"
                                                                autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                                [showClear]="true"></p-dropdown>
                                                            <hr class="my-0">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    <div class="row mt-5">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">Seleccionar los Tipos de Configuracion:*
                                                </span>
                                                <i class="fa-solid fa-truck color-icon" style="font-size: 1.3rem;"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2">
                                                    <p-multiSelect [options]="cat_ConfiguracionUnidades"
                                                        [(ngModel)]="configuracionesSeleccionadas"
                                                        defaultLabel="Selecciona uno o varios Tipos de Configuraciones"
                                                        autoWidth="false" optionValue="idx_respaldo_confunidad"
                                                        formControlName="configuracion_Unidad" [showClear]="false"
                                                        optionLabel="nombre" [appendTo]="'body'" (ngModelChange)="Config()"
                                                        (onChange)="handleChange($event)"
                                                        display="chip"></p-multiSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let configuracionimporte of importesForms; let i = index" [formGroup]="configuracionimporte"
                                        class="form-card col-md-12 mt-3">
                                        <div class="card kolor col-md-6">
                                            <div class="card-body col-md-12">
                                                <div class="row col-md-16">
                                                    <div class="col-md-16">
                                                        <div class="row mb-2">
                                                            <h5 class="texto_negrita">COSTO CASETA : <span
                                                                    class="texto_naranja_ba">{{configuracionimporte.get('nombre')?.value }}
                                                                    <i class="fa-solid fa-truck-ramp-box"
                                                                        style="color: black; font-size: 1.6rem;"></i></span>
                                                            </h5>
                                                        </div>
                                                        <div class="row col-md-18">
                                                            <div class="row col-md-6">
                                                                <p-inputNumber class="mt-2" placeholder="Ingrese el Importe de Caseta"
                                                                    mode="currency" currency="MXN" formControlName="importeCasetaConfiguracion"
                                                                    autoWidth="false" pTooltip="Costo de las Casetas"
                                                                    [style]="{'width':'100%'}" [showClear]="true"
                                                                    tooltipPosition="left">
                                                                </p-inputNumber>
                                                            </div>
                                                            <div class="row col-md-6 ml-5">
                                                                <button (click)="addImporteForm(configuracionimporte,i)" pButton pRipple
                                                                    class="p-button-raised ml-3" icon="pi pi-plus-circle"  [disabled]="!configuracionimporte.valid"
                                                                    pTooltip="Almacenar informacion"
                                                                    style="width: 100%;font-weight: bold; font-size: 1.1rem;">&nbsp;
                                                                    Guardar Importe</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 85px;">
                                                    <span style="color: #2454B8;"><b>Kilometros:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-truck-fast color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        placeholder="Ingrese los Kilometros*"
                                                        min = "1"
                                                        formControlName="kilometros">
                                                    <hr class="my-0">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Horas Carga y Descarga</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0 ml-3">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-regular fa-clock color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Horas de Carga y Descarga" style="font-weight: bold;"
                                                        formControlName="hrs_carga_descarga">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Numero de Choferes:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Choferes" formControlName="num_choferes">
                                                    <hr class="my-0">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Numero de Auxiliares:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Auxiliares"
                                                        formControlName="num_auxiliares">
                                                    <hr class="my-0">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>% Porcentaje de UTD</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-scale-balanced color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        style="font-weight: bold;" pTooltip="Porcentaje de Utilidad"
                                                        formControlName="porcentaje_UTD">
                                                    <hr class="my-0">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="form-group col-md-6">
                                            <div class="row g-0">
                                                <div class="col-sm-4 mx-0 d-flex align-items-center">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-circle-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                    <p class="color-icon mt-2 ml-2"
                                                        style="display: inline-block; font-weight: bold;">Tipo de
                                                        Cliente</p>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p-dropdown [options]="catalogo_TipoCliente"
                                                        [(ngModel)]="TipoClienteSeleccionado"
                                                        optionLabel="des_TipoCliente" formControlName="tipo_cliente"
                                                        placeholder="Seleccione el Tipo de Cliente" autoWidth="false"
                                                        [style]="{'width':'100%'}" optionValue="id_TipoCliente"
                                                        [appendTo]="'body'" [showClear]="true"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="TipoClienteSeleccionado==2" class="col-md-6">
                                            <div class="g-0">
                                                <span style="color: #2454B8;"><b>$ Total de la Comision</b></span>
                                                <div class="col-sm-4 pl-1">
                                                    <p-inputNumber placeholder="Ingrese el Monto de la Comision*"
                                                        class="mi-clase-ancha" formControlName="comision_cliente"
                                                        mode="currency" currency="MXN" autoWidth="false"
                                                        pTooltip="Monto de la Comision" [style]="{'width':'80%'}"
                                                        [showClear]="true" tooltipPosition="left">
                                                    </p-inputNumber>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-3">
                                        <div class="col-4">
                                            <p *ngIf="!tabuladorForm.valid" class="texto-invalido text-right">
                                                <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                                    Proporcionar
                                                    La Informaci&oacute;n Obligatoria(*)</i>
                                            </p>
                                            <p *ngIf="tabuladorForm.valid" class="texto-valido text-right">
                                                <i class="pi pi-check-circle"
                                                    style="font-size: 1rem;">Informaci&oacute;n
                                                    Completa</i>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-primary form-control" (click)="guardarInformacionTabulador()" 
                                                [disabled]="!tabuladorForm.valid" pTooltip="Almacenar informacion"
                                                tooltipPosition="right">
                                                <span class="text-white">Guardar</span>
                                                <i class="pi pi-save text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="ml-1" style="width: 30%;">
                    <div style="width: 100%; padding-right: 10px;">
                        <div class="mt-5">
                            <div class="card-content card-shadow sinborde">
                                <form class="form_cliente ml-2" style="height: 780px;" [formGroup]="tabuladorForm"
                                    (ngSubmit)="guardarInformacionTabulador()">
                                    <div class="ml-2 px-2">
                                        <span class="title-light" style="font-size:1.3rem;">Contenido Extra<i
                                                class="pi pi-cog" style="size: 2.2rem;"></i></span>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">(CASETAS) Se Incluye en Cotización o
                                                    Individual :*
                                                </span>
                                                <p-checkbox pTooltip="Seleccione en Caso para Agregar en Importe"
                                                    [binary]="true" (click)="Individual()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Auxiliares"
                                                        formControlName="imp_peajeindividual_extr">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">(CANCELADO) Ingrese el Porcentaje de Flete
                                                    en Falso*
                                                </span>
                                                <p-checkbox [binary]="true" (click)="Cancelado()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <i class="fa-solid fa-percent color-icon"
                                                        style="font-size: 1.5rem;"></i>&nbsp;
                                                    <input type="number" class="form-control sinborde"
                                                        formControlName="imp_porcentajecancelado_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4 form-card">
                                        <span style="color: #2454B8;">
                                            <h2><i class="fa-solid fa-screwdriver-wrench color-icon"
                                                    style="font-size: 2rem;"></i>&nbsp;MANIOBRAS</h2>
                                        </span>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es Descarga Completa:</span>
                                                &nbsp;<p-checkbox [binary]="true"
                                                    (click)="DescargaCompleta()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <span class="texto_negrita">Importe de la Descarga<i
                                                        class="fa-solid fa-money-check-dollar"
                                                        style="font-size: 1.3rem;"></i>
                                                </span>
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Importe total de la descarga"
                                                        formControlName="imp_descargaCompleta_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es persona de Happy:
                                                </span>
                                                <p-checkbox [binary]="true" (click)="PersonaHappy()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2">
                                                <span class="texto_negrita">Numero de personas
                                                </span>
                                                <div class="mt-1 ml-2">
                                                    <div class="col-sm-6 pl-2"
                                                        style="display: flex; align-items: center;">
                                                        <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                        <input type="number" class="form-control sinborde"
                                                            pTooltip="Numero de Personas"
                                                            formControlName="num_Personas_extr" [disabled]="true">
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="texto_negrita">Importe por Persona <i
                                                    class="fa-solid fa-money-check-dollar"
                                                    style="font-size: 1.3rem;"></i></span>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Personas"
                                                        formControlName="imp_Persona_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es personal Subcontratado:
                                                </span>
                                                <p-checkbox [binary]="true" (click)="subcontratado()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2">
                                                <span class="texto_negrita">Importe Total de Subcontrato <i
                                                        class="fa-solid fa-money-check-dollar"
                                                        style="font-size: 1.3rem;"></i>
                                                </span>
                                                <div class="mt-1 ml-2">
                                                    <div class="col-sm-6 pl-2"
                                                        style="display: flex; align-items: center;">
                                                        <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                        <input type="number" class="form-control sinborde"
                                                            pTooltip="Numero de Personas"
                                                            formControlName="imp_Subcontrato_extr">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="card-content" *ngIf="dspTablaRegreso">
            <div class="row mt-3 mx-0 px-1">
                <div class="form-check form-card">
                    <input class="form-check-input " type="checkbox" id="viewModeCheckbox"
                        (change)="actualizarLabelTexto()" [(ngModel)]="isListView">
                    <label class="form-check-label texto_negrita" for="viewModeCheckbox">
                        <i style="font-size: 1.4rem;" class="pi"
                            [ngClass]="{'pi-list': !isListView, 'pi-table': isListView}"></i>
                        {{ texto }}
                    </label>
                </div>
                <ng-container *ngIf="!isListView; else listViewTemplate">
                    <p-table #dt2 [value]="tablaRespuesta" [tableStyle]="{'min-width': '100rem'}"
                        styleClass="p-datatable-sm p-datatable-striped" dataKey="id" responsiveLayout="scroll"
                        [scrollable]="true" scrollDirection="both" scrollHeight="580px" [paginator]="true" [rows]="10"
                        [rowsPerPageOptions]="[10, 25, 50,100]" [loading]="loading" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        [globalFilterFields]="['id_corrida','configuracion','origen','destino','costo','imp_preciodeventa','imp_utilidad',
                    'imp_utilidadmensual','imp_sueldochofer','imp_sueldoaux','imp_combustible','imp_comisioncombustible','imp_cel_gps','imp_imss','imp_hospedaje','imp_viaticos']">
                        <ng-template pTemplate="header">
                            <tr [ngClass]="{'texto_sm':true}">
                                <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                                    #
                                </th>
                                <th pFrozenColumn style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        No. Tabulado
                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Conf. Unidad
                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Origen

                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Destino
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Numero de Dias
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Costo
                                        <p-sortIcon field="costo"></p-sortIcon>
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Porcentaje UTD
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Precio de Venta
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Utilidad
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Comisión a terceros
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Sueldo Chofer
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Sueldo Auxiliar
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Combustible
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Comision Combustible
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Urea
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Celular GPS
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        IMSS
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Hospedaje
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Viaticos
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">

                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-index="rowIndex">
                            <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                    {{(index+1)}}
                                </td>
                                <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                    <div class="texto_negrita">{{data.id_corrida}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba">
                                    <div class="texto_negrita"> {{data.configuracion}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true">
                                    <div class="texto_negrita texto_verde_oliva">{{data.origen}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true">
                                    <div class="texto_negrita texto_verde_oliva">{{data.destino}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.num_dias}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.costo| currency}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">%{{data.num_porcentajeutd}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita colo">{{data.imp_preciodeventa| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_utilidad| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_terceros| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_sueldochofer| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_sueldoaux| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_combustible| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_comisioncombustible| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_urea| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_cel_gps| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_imss| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_hospedaje| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_viaticos| currency}}</div>
                                </td>
                                <td>
                                    <button pButton icon="pi pi-save" styleClass="p-button-sm"
                                        style="margin-right: 10px !important; background-color: rgb(30, 155, 30); width: 52px; border: none;"
                                        pTooltip="Guardar Tabulado" tooltipPosition="top"
                                        (click)="GuardarTabulado(data);">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
                <ng-template #listViewTemplate>
                    <!-- Lista/columnas hacia abajo -->
                    <div class="row mt-3 mx-0 px-1 justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-3" *ngFor="let data of tablaRespuesta">
                            <!-- Contenido de cada fila -->
                            <div class="card h-60">
                                <div class="card-body">
                                    <div class="col flex">
                                        <div class="col-md-12" style="font-size: 1.2rem;">
                                            <h4 class="card-title texto_negrita align-items-left">
                                                Configuración: <p class="texto_verde_oliva">{{data.configuracion}}</p>
                                            </h4>
                                            <div class="row">
                                                <!-- Columna izquierda -->
                                                <div class="col-md-6">
                                                    <p class="card-title texto_negrita">Numero de Corrida: <span
                                                            class="texto_naranja_ba">#</span>{{ data.id_corrida }}</p>
                                                    <p class="card-text texto_negrita">Origen: <span
                                                            class="texto_naranja_ba">{{ data.origen }}</span></p>
                                                    <p class="card-text texto_negrita">Destino: <span
                                                            class="texto_naranja_ba">{{ data.destino }}</span></p>
                                                    <p class="card-text texto_negrita">Número de Días: {{ data.num_dias
                                                        }}</p>
                                                    <p class="card-text texto_negrita">Costo: <span class="colo">{{
                                                            data.costo | currency }}</span></p>
                                                    <p class="card-text texto_negrita">Precio de Venta: <span
                                                            class="colo">{{ data.imp_preciodeventa |currency }}</span>
                                                    </p>
                                                    <p class="card-text texto_negrita">Utilidad: <span class="colo">{{
                                                            data.imp_utilidad | currency }}</span>&nbsp;<i
                                                            style="font-size: 1.3rem;"
                                                            class="pi pi-star texto_amarillo_mostaza"></i></p>
                                                    <p class="card-text texto_negrita">Comision a terceros: <span
                                                        class="colo">{{ data.imp_terceros |currency }}</span>
                                                    </p>
                                                    <p class="card-text texto_negrita">Porcentaje UTD: <span
                                                            class="texto_naranja_ba">%{{ data.num_porcentajeutd
                                                            }}</span></p>
                                                    <p class="card-text texto_negrita">Sueldo Chofer: <span
                                                            class="colo">{{ data.imp_sueldochofer |currency }}</span>
                                                    </p>
                                                </div>
                                                <!-- Columna derecha -->
                                                <div class="col-md-6">
                                                    <p class="card-text texto_negrita">Sueldo Auxiliar: <span
                                                            class="colo">{{ data.imp_sueldoaux |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Combustible: <span
                                                            class="colo">{{ data.imp_combustible |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Comisión Combustible: <span
                                                            class="colo">{{data.imp_comisioncombustible | currency
                                                            }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Celular GPS: <span
                                                            class="colo">{{ data.imp_cel_gps |currency }}</span></p>
                                                    <p class="card-text texto_negrita">IMSS: <span class="colo">{{
                                                            data.imp_imss | currency }}</span></p>
                                                    <p class="card-text texto_negrita">Mantenimiento: <span
                                                            class="colo">{{ data.imp_mantenimiento | currency }}</span>
                                                    </p>
                                                    <p class="card-text texto_negrita">Depreciacion: <span
                                                            class="colo">{{data.imp_depreciacion | currency}}</span></p>
                                                    <p class="card-text texto_negrita">Urea: <span
                                                        class="colo">{{data.imp_urea | currency}}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Hospedaje: <span
                                                            class="colo">{{ data.imp_hospedaje |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Viáticos: <span
                                                            class="colo">{{ data.imp_viaticos |currency }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col flex align-items-center justify-content-center mt-3">
                                        <img *ngIf="data.configuracion==='LV'" src="../../../assets/images/van.png"
                                            alt="Imagen" class="img-fluid" pTooltip="LV">
                                        <img *ngIf="data.configuracion==='3.5'" src="../../../assets/images/3.5.png"
                                            alt="Imagen" class="img-fluid" pTooltip="3.5">
                                        <img *ngIf="data.configuracion==='RABON'" src="../../../assets/images/rabon.png"
                                            alt="Imagen" class="img-fluid" pTooltip="RABON">
                                        <img *ngIf="data.configuracion==='THORTON'"
                                            src="../../../assets/images/rabon.png" alt="Imagen" class="img-fluid"
                                            pTooltip="THORTON">
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button pButton label="Guardar Tabulado" icon="pi pi-save" styleClass="p-button-sm"
                                        style="background-color: rgb(30, 155, 30); border: none;"
                                        pTooltip="Guardar Tabulado" tooltipPosition="top"
                                        (click)="GuardarTabulado(data);">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #listViewTemplate>
                    <!-- Lista/columnas hacia abajo -->
                    <div class="row mt-3 mx-0 px-1 justify-content-center">
                        <div class="col-lg-6 col-md-6 col-sm-12 mb-3" *ngFor="let data of tablaRespuesta">
                            <!-- Contenido de cada fila -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title texto_negrita align-items-center">Configuración: {{
                                                data.configuracion }}</h5>
                                            <p class="card-title texto_negrita">Numero de Corrida: {{ data.id_corrida }}
                                            </p>
                                            <!-- Resto del contenido -->
                                        </div>
                                        <div class="card-footer">
                                            <button pButton label="Guardar Tabulado" icon="pi pi-save"
                                                styleClass="p-button-sm"
                                                style="background-color: rgb(30, 155, 30); border: none;"
                                                pTooltip="Guardar Tabulado" tooltipPosition="top"
                                                (click)="GuardarTabulado(data);">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!-- Imagen -->
                                    <img src="ruta/a/la/imagen.jpg" alt="Imagen" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="align-items-center mt-4">
                    <div class="d-flex justify-content-around">
                        <button class="btn btn-info" pButton icon="pi pi-undo" styleClass="p-button-sm"
                            pTooltip="Nuevo Tabulado" label="Generar Nuevo Tabulado"
                            style="margin-right: 10px !important;width: 52px; width: 18rem;" tooltipPosition="top"
                            (click)="GenerarNuevoTabulado();">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspFrmCotizacion" [modal]="true"
        [style]="{width: '65vw','height':'1200px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            Formulario Cotizacion
        </ng-template>
        <ng-template pTemplate="content">
            <div class="container" style="width: 90%;">
                <div class="form-card">
                    <div class="card-content card-shadow sinborde">
                        <form class="form_cliente" [formGroup]="CotizacionForm">
                            <div class="ml-1 px-1">
                                <div class="row col-md-18 d-flex align-items-center">
                                    <div class="col-md-5" style="padding-bottom: 50px;">
                                        <span class="title-light">Formulario de Cotizacion&nbsp;<i
                                                class="fa-solid fa-calculator" style="size: 2.3rem;"></i></span>
                                        <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
                                    </div>
                                    <!-- 
                                    <div class="col-md-7 text-end">
                                        <img src="../../../assets/images/logo_centrado.png" alt="Imagen"
                                            class="img-fluid" style="height: 90px; width: 350px;" pTooltip="jappy">
                                    </div>
                                    -->
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-regular fa-user color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11 pl-2">
                                                <p-dropdown [options]="cat_nombresContacto"
                                                    optionLabel="NombreCompletoCto" formControlName="nom_Contacto"
                                                    pTooltip="Nombre de Contacto"
                                                    placeholder="Seleccione el Nombre del Contacto*" autoWidth="false"
                                                    [style]="{'width':'100%'}" optionValue="NombreCompletoCto"
                                                    [showClear]="true" tooltipPosition="left"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-list-ol color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    placeholder="Numero de Dias Operativos*"
                                                    formControlName="num_DiasOperativos"
                                                    style="text-transform:uppercase;" pTooltip="Dias Operativos"
                                                    tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-calendar color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control sinborde"
                                                    formControlName="fecha_emision" style="text-transform:uppercase;"
                                                    pTooltip="Fecha de Emision" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-calendar-week color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11 pl-2">
                                                <p-dropdown [options]="cat_diasoperacion"
                                                    optionLabel="des_diasoperacion"
                                                    formControlName="id_jornadaOperacion" pTooltip="Tipo de Jornada"
                                                    placeholder="Seleccione el tipo de jornada*" autoWidth="false"
                                                    [style]="{'width':'100%'}" optionValue="id_diasoperacion"
                                                    [showClear]="true" tooltipPosition="left"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-hourglass-half color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde" placeholder="Horas de Espera en Descarga"
                                                    formControlName="hrs_EsperaDescarga"
                                                    style="text-transform:uppercase;"
                                                    pTooltip="Horas de espera en Descarga" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-triangle-exclamation color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="hrs_Advertencia" style="text-transform:uppercase;"
                                                    pTooltip="Advertencia de Horas de Espera" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-regular fa-clock color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="hrs_bloqueEstadia"
                                                    style="text-transform:uppercase;"
                                                    pTooltip="Bloque de Horas por Estadia" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-percentage color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_porcentajeFleteFalso"
                                                    pTooltip="Porcentaje de Flete en Falso" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-percentage color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_porcentajeFleteRetorno"
                                                    pTooltip="Tarifa por Flete de Retorno" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-calendar-day color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_diasCredito" placeholder="(1-120)"
                                                    pTooltip="Numero de Dias de Credito (1-120)"
                                                    tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-file-contract color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11 pl-2">
                                                <p-dropdown [options]="cat_periodofacturacion"
                                                    optionLabel="des_periodofacturacion" formControlName="id_tipoEvento"
                                                    pTooltip="Tipo de Jornada"
                                                    placeholder="Seleccione el tipo de Facturacion*" autoWidth="false"
                                                    [style]="{'width':'100%'}" optionValue="id_periodofacturacion"
                                                    [showClear]="true" tooltipPosition="left"></p-dropdown>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i
                                                        class="fa-solid fa-hand-point-right color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_VigenciaTarifaria"
                                                    placeholder="Vigencia Tarifaria"
                                                    pTooltip="Vigencia Tarifaria en Meses" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-tags color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_DiasValidez"
                                                    placeholder="Numero de dias de validez"
                                                    pTooltip="Numero de dias de validez" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row g-0">
                                            <div class="col-sm-1 mx-0">
                                                <span class="input-group-addon"> <i class="pi pi-tags color-icon"
                                                        style="font-size: 2rem"></i>
                                                </span>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="number" class="form-control sinborde"
                                                    formControlName="num_cantidadrepartos"
                                                    placeholder="Cantidad de Repartos"
                                                    pTooltip="Cantidad de Repartos" tooltipPosition="right">
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center mt-3 ml-2">
                                    <div *ngIf="this.bntDescargarCotizacion===true" class="col-3 mb-2">
                                        <button pButton label="Generar Cotizacion PDF" icon="pi pi-file-word"
                                                styleClass="p-button-sm" class="jover"
                                                style="background-color: rgb(30, 88, 155); border: none;"
                                                pTooltip="Cotizacion" tooltipPosition="top"
                                                (click)="GenerarCotizacion();">
                                        </button>
                                    </div>
                                    <div class="col-4">
                                        <p *ngIf="!CotizacionForm.valid" class="texto-invalido text-right">
                                            <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                                Proporcionar
                                                La Informaci&oacute;n Obligatoria(*)</i>
                                        </p>
                                        <p *ngIf="CotizacionForm.valid" class="texto-valido text-right">
                                            <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                                Completa</i>
                                        </p>
                                    </div>
                                    <div class="col-3 ml-3">
                                        <button class="btn btn-primary form-control p-button-lg" (click)="guardarFrmCotizacion()"
                                            [disabled]="!CotizacionForm.valid" pTooltip="Almacenar informacion"
                                            tooltipPosition="right">
                                            <span class="text-white">Guardar Datos</span>
                                            <i class="pi pi-file-pdf text-white" style="font-size: 1.3rem;"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text"
                (click)="CerrarFrmCotizacion()"></button>
        </ng-template>
    </p-dialog>
</div>

<p-dialog header="Grafica del Costo Total" [modal]="true" [(visible)]="dspGrafica"
    [style]="{ width: '90vh',height:'900px'}" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [maximizable]="false" (onHide)="CerrarGrafica()">
    <ng-template pTemplate="container">
        <div class="card-content card-shadow">
            <div class="form-card mt-5 ml-5"
                pTooltip="Costo = Peaje + sueldo chofer + sueldo auxiliar + combustible + comision combustible + Monitoreo + RH Diario + depreciacion + mantenimiento + hospedaje + viaticos + operaciones + seguridadpatrimonia">
                <div class="justify-content-center ml-2">
                    <p-chart type="doughnut" [data]="data" [options]="options" class="ui-chart ui-chart-series" />
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text"
            (click)="CerrarGrafica()"></button>
    </ng-template>
</p-dialog>

<p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspEditarTAbulado" [modal]="true"
        [style]="{width: '90vw','height':'1150px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            Editar Tabulado Tabulacion
        </ng-template>
        <ng-template pTemplate="content">
            <div style="display: flex;" *ngIf="dspTablaRegreso===false">
                <div style="width: 65%; padding-right: 10px;">
                    <div class="mt-5">
                        <div class="card-content card-shadow sinborde">
                            <form class="form_cliente" [formGroup]="EditarTabuladoForm">
                                <div class="ml-2 px-2">
                                    <span class="title-light" style="font-size:1.3rem;">Contenido del Tabulador<i
                                            class="pi pi-cog" style="size: 2.2rem;"></i></span>
                                    <div class="row mt-5">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">Seleccionar los Tipos de Configuracion:*
                                                </span>
                                                <i class="fa-solid fa-truck color-icon" style="font-size: 1.3rem;"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2">
                                                    <p-dropdown [options]="cat_ConfiguracionUnidades"
                                                        [(ngModel)]="configuracionSeleccionada"
                                                        defaultLabel="Selecciona uno o varios Tipos de Configuraciones"
                                                        autoWidth="false" optionValue="idx_respaldo_confunidad"
                                                        formControlName="configuracion_Unidad" [showClear]="false"
                                                        optionLabel="nombre" [appendTo]="'body'" (ngModelChange)="Configi()"
                                                        (onChange)="handleChange($event)"
                                                        display="chip"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let configuracionimporte of importesForm; let i = index" [formGroup]="configuracionimporte"
                                        class="form-card col-md-12 mt-3">
                                        <div class="card kolor col-md-6">
                                            <div class="card-body col-md-12">
                                                <div class="row col-md-16">
                                                    <div class="col-md-16">
                                                        <div class="row mb-2">
                                                            <h5 class="texto_negrita">COSTO CASETA : <span
                                                                    class="texto_naranja_ba">{{configuracionimporte.get('nombre')?.value }}
                                                                    <i class="fa-solid fa-truck-ramp-box"
                                                                        style="color: black; font-size: 1.6rem;"></i></span>
                                                            </h5>
                                                        </div>
                                                        <div class="row col-md-18">
                                                            <div class="row col-md-6">
                                                                <p-inputNumber class="mt-2" placeholder="Ingrese el Importe de Caseta"
                                                                    mode="currency" currency="MXN" formControlName="importeCasetaConfiguracion"
                                                                    autoWidth="false" pTooltip="Costo de las Casetas"
                                                                    [style]="{'width':'100%'}" [showClear]="true"
                                                                    tooltipPosition="left">
                                                                </p-inputNumber>
                                                            </div>
                                                            <div class="row col-md-6 ml-5">
                                                                <button (click)="addImporteForm(configuracionimporte,i)" pButton pRipple
                                                                    class="p-button-raised ml-3" icon="pi pi-plus-circle"  [disabled]="!configuracionimporte.valid"
                                                                    pTooltip="Almacenar informacion"
                                                                    style="width: 100%;font-weight: bold; font-size: 1.1rem;">&nbsp;
                                                                    Guardar Importe</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5 form-card">
                                        <span style="color: #2454B8;">
                                            <h2><i class="pi pi-wallet" style="font-size: 2rem;"></i> Informacion Base
                                                Para el Cliente:</h2>
                                        </span>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="form-group col-md-4">
                                            <div>
                                                <span class="texto_negrita">Seleccionar el Tipo de Ruta:* </span>
                                            </div>
                                            <div>
                                            </div>
                                            <div class="row mt-2 ml-2">
                                                <div class="col-sm-6 pl-2">
                                                    <p-dropdown [options]="cat_Rutas"
                                                        placeholder="Selecciona el Tipo de Ruta" autoWidth="false"
                                                        optionLabel="nom_area" [(ngModel)]="idruta"
                                                        optionValue="id_area" formControlName="Area" [showClear]="true"
                                                        [appendTo]="'body'"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0">
                                                <div class="col-sm-3 mx-0 d-flex align-items-center">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-flag-usa color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                    <p class="color-icon mt-2 ml-1"
                                                        style="display: inline-block; font-weight: bold;">Origen</p>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p-dropdown *ngIf="idruta==1" [options]="municipioscat"
                                                        optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                        [showClear]="true" formControlName="origen"
                                                        placeholder="Seleccione el Origen" autoWidth="false"
                                                        (onChange)="getFiltradoMunicipios($event)"
                                                        [style]="{'width':'100%'}" optionValue="id"></p-dropdown>
                                                    <p-dropdown *ngIf="idruta==2" [options]="municipioscat"
                                                        optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                        [showClear]="true" formControlName="origen"
                                                        placeholder="Seleccione el Origen" autoWidth="false"
                                                        [style]="{'width':'100%'}" optionValue="id"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0">
                                                <div class="col-sm-4 mx-0 d-flex align-items-center">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-city color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                    <p class="color-icon mt-2 mr-1"
                                                        style="display: inline-block; font-weight: bold;">Destino</p>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p-dropdown *ngIf="idruta==1" [options]="MunicipiosFiltrados"
                                                        optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                        formControlName="destino" placeholder="Seleccione el Destino"
                                                        autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                        [showClear]="true"></p-dropdown>
                                                    <p-dropdown *ngIf="idruta==2" [options]="municipioscat"
                                                        optionLabel="municipio" [filter]="true" filterBy="municipio"
                                                        formControlName="destino" placeholder="Seleccione el Destino"
                                                        autoWidth="false" [style]="{'width':'100%'}" optionValue="id"
                                                        [showClear]="true"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 85px;">
                                                    <span style="color: #2454B8;"><b>Kilometros:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-truck-fast color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        placeholder="Ingrese los Kilometros*"
                                                        formControlName="kilometros">
                                                    <hr class="my-0">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Horas Carga y Descarga</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0 ml-3">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-regular fa-clock color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Horas de Carga y Descarga" style="font-weight: bold;"
                                                        formControlName="hrs_carga_descarga">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Numero de Choferes:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Choferes" formControlName="num_choferes">
                                                    <hr class="my-0">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>Numero de Auxiliares:*</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i class="pi pi-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Auxiliares"
                                                        formControlName="num_auxiliares">
                                                    <hr class="my-0">

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="row g-0 mb-20">
                                                <div style="margin-bottom: 15px; margin-left: 50px;">
                                                    <span style="color: #2454B8;"><b>% Porcentaje de UTD</b></span>
                                                </div>
                                                <div class="col-sm-2 mx-0">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-scale-balanced color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 pl-1">
                                                    <input type="number" class="form-control sinborde"
                                                        style="font-weight: bold;" pTooltip="Porcentaje de Utilidad"
                                                        formControlName="porcentaje_UTD">
                                                    <hr class="my-0">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="form-group col-md-6">
                                            <div class="row g-0">
                                                <div class="col-sm-4 mx-0 d-flex align-items-center">
                                                    <span class="input-group-addon"> <i
                                                            class="fa-solid fa-circle-user color-icon"
                                                            style="font-size: 2rem"></i>
                                                    </span>
                                                    <p class="color-icon mt-2 ml-2"
                                                        style="display: inline-block; font-weight: bold;">Tipo de
                                                        Cliente</p>
                                                </div>
                                                <div class="col-sm-8">
                                                    <p-dropdown [options]="catalogo_TipoCliente"
                                                        [(ngModel)]="TipoClienteSeleccionado"
                                                        optionLabel="des_TipoCliente" formControlName="tipo_cliente"
                                                        placeholder="Seleccione el Tipo de Cliente" autoWidth="false"
                                                        [style]="{'width':'100%'}" optionValue="id_TipoCliente"
                                                        [appendTo]="'body'" [showClear]="true"></p-dropdown>
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="TipoClienteSeleccionado==2" class="col-md-6">
                                            <div class="g-0">
                                                <span style="color: #2454B8;"><b>$ Total de la Comision</b></span>
                                                <div class="col-sm-4 pl-1">
                                                    <p-inputNumber placeholder="Ingrese el Monto de la Comision*"
                                                        class="mi-clase-ancha" formControlName="comision_cliente"
                                                        mode="currency" currency="MXN" autoWidth="false"
                                                        pTooltip="Monto de la Comision" [style]="{'width':'80%'}"
                                                        [showClear]="true" tooltipPosition="left">
                                                    </p-inputNumber>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex justify-content-center mt-3">
                                        <div class="col-4">
                                            <p *ngIf="!EditarTabuladoForm.valid" class="texto-invalido text-right">
                                                <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario
                                                    Proporcionar
                                                    La Informaci&oacute;n Obligatoria(*)</i>
                                            </p>
                                            <p *ngIf="EditarTabuladoForm.valid" class="texto-valido text-right">
                                                <i class="pi pi-check-circle"
                                                    style="font-size: 1rem;">Informaci&oacute;n
                                                    Completa</i>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-primary form-control" (click)="guardarInformacionEditarTabulador()" 
                                                [disabled]="!EditarTabuladoForm.valid" pTooltip="Almacenar informacion"
                                                tooltipPosition="right">
                                                <span class="text-white">Guardar</span>
                                                <i class="pi pi-save text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="ml-1" style="width: 30%;">
                    <div style="width: 100%; padding-right: 10px;">
                        <div class="mt-5">
                            <div class="card-content card-shadow sinborde">
                                <form class="form_cliente ml-2" style="height: 780px;" [formGroup]="tabuladorForm"
                                    (ngSubmit)="guardarInformacionTabulador()">
                                    <div class="ml-2 px-2">
                                        <span class="title-light" style="font-size:1.3rem;">Contenido Extra<i
                                                class="pi pi-cog" style="size: 2.2rem;"></i></span>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">(CASETAS) Se Incluye en Cotización o
                                                    Individual :*
                                                </span>
                                                <p-checkbox pTooltip="Seleccione en Caso para Agregar en Importe"
                                                    [binary]="true" (click)="Individual()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Auxiliares"
                                                        formControlName="imp_peajeindividual_extr">
                                                    <hr class="my-0">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita">(CANCELADO) Ingrese el Porcentaje de Flete
                                                    en Falso*
                                                </span>
                                                <p-checkbox [binary]="true" (click)="Cancelado()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <i class="fa-solid fa-percent color-icon"
                                                        style="font-size: 1.5rem;"></i>&nbsp;
                                                    <input type="number" class="form-control sinborde"
                                                        formControlName="imp_porcentajecancelado_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4 form-card">
                                        <span style="color: #2454B8;">
                                            <h2><i class="fa-solid fa-screwdriver-wrench color-icon"
                                                    style="font-size: 2rem;"></i>&nbsp;MANIOBRAS</h2>
                                        </span>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es Descarga Completa:</span>
                                                &nbsp;<p-checkbox [binary]="true"
                                                    (click)="DescargaCompleta()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2 ml-2">
                                                <span class="texto_negrita">Importe de la Descarga<i
                                                        class="fa-solid fa-money-check-dollar"
                                                        style="font-size: 1.3rem;"></i>
                                                </span>
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Importe total de la descarga"
                                                        formControlName="imp_descargaCompleta_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es persona de Happy:
                                                </span>
                                                <p-checkbox [binary]="true" (click)="PersonaHappy()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2">
                                                <span class="texto_negrita">Numero de personas
                                                </span>
                                                <div class="mt-1 ml-2">
                                                    <div class="col-sm-6 pl-2"
                                                        style="display: flex; align-items: center;">
                                                        <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                        <input type="number" class="form-control sinborde"
                                                            pTooltip="Numero de Personas"
                                                            formControlName="num_Personas_extr" [disabled]="true">
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="texto_negrita">Importe por Persona <i
                                                    class="fa-solid fa-money-check-dollar"
                                                    style="font-size: 1.3rem;"></i></span>
                                            <div class="mt-2 ml-2">
                                                <div class="col-sm-6 pl-2" style="display: flex; align-items: center;">
                                                    <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                    <input type="number" class="form-control sinborde"
                                                        pTooltip="Numero de Personas"
                                                        formControlName="imp_Persona_extr">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div>
                                                <span class="texto_negrita"> Es personal Subcontratado:
                                                </span>
                                                <p-checkbox [binary]="true" (click)="subcontratado()"></p-checkbox>
                                                <i style="font-size: 1.5rem; margin-right: 10px;"
                                                    class="fa-solid fa-question color-icon"></i>
                                            </div>
                                            <div class="mt-2">
                                                <span class="texto_negrita">Importe Total de Subcontrato <i
                                                        class="fa-solid fa-money-check-dollar"
                                                        style="font-size: 1.3rem;"></i>
                                                </span>
                                                <div class="mt-1 ml-2">
                                                    <div class="col-sm-6 pl-2"
                                                        style="display: flex; align-items: center;">
                                                        <!-- Ajusta el estilo del input para que se alinee con el checkbox -->
                                                        <input type="number" class="form-control sinborde"
                                                            pTooltip="Numero de Personas"
                                                            formControlName="imp_Subcontrato_extr">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="card-content" *ngIf="dspTablaRegreso">
            <div class="row mt-3 mx-0 px-1">
                <div class="form-check form-card">
                    <input class="form-check-input " type="checkbox" id="viewModeCheckbox"
                        (change)="actualizarLabelTexto()" [(ngModel)]="isListView">
                    <label class="form-check-label texto_negrita" for="viewModeCheckbox">
                        <i style="font-size: 1.4rem;" class="pi"
                            [ngClass]="{'pi-list': !isListView, 'pi-table': isListView}"></i>
                        {{ texto }}
                    </label>
                </div>
                <ng-container *ngIf="!isListView; else listViewTemplate">
                    <p-table #dt2 [value]="TablaRespuestaEditar" [tableStyle]="{'min-width': '100rem'}"
                        styleClass="p-datatable-sm p-datatable-striped" dataKey="id" responsiveLayout="scroll"
                        [scrollable]="true" scrollDirection="both" scrollHeight="580px" [paginator]="true" [rows]="10"
                        [rowsPerPageOptions]="[10, 25, 50,100]" [loading]="loading" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        [globalFilterFields]="['id_corrida','configuracion','origen','destino','costo','imp_preciodeventa','imp_utilidad',
                    'imp_utilidadmensual','imp_sueldochofer','imp_sueldoaux','imp_combustible','imp_comisioncombustible','imp_cel_gps','imp_imss','imp_hospedaje','imp_viaticos']">
                        <ng-template pTemplate="header">
                            <tr [ngClass]="{'texto_sm':true}">
                                <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                                    #
                                </th>
                                <th pFrozenColumn style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        No. Tabulado
                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Conf. Unidad
                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Origen

                                    </div>
                                </th>
                                <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Destino
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Numero de Dias
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Numero de kilometros
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Peaje
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Costo
                                        <p-sortIcon field="costo"></p-sortIcon>
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Porcentaje UTD
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Precio de Venta
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Utilidad
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Numero de Choferes
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Numero de Auxiliares
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Sueldo Chofer
                                    </div>
                                </th>
                                <th style="min-width:8rem;width: 8rem;">
                                    <div class="flex align-items-center">
                                        Sueldo Auxiliar
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Combustible
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Comision Combustible
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Urea
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Celular GPS
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        IMSS
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Hospedaje
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">
                                    <div class="flex align-items-center">
                                        Gasto Viaticos
                                    </div>
                                </th>
                                <th style="min-width:10rem;width: 10rem;">

                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data let-index="rowIndex">
                            <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                    {{(index+1)}}
                                </td>
                                <td pFrozenColumn [frozen]="true" class="texto_negrita">
                                    <div class="texto_negrita">{{data.id_corrida}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba">
                                    <div class="texto_negrita"> {{data.configuracion}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true">
                                    <div class="texto_negrita texto_verde_oliva">{{data.origen}}</div>
                                </td>
                                <td pFrozenColumn [frozen]="true">
                                    <div class="texto_negrita texto_verde_oliva">{{data.destino}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.num_dias}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.num_kilometros}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_peaje| currency}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.costo| currency}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">%{{data.num_porcentajeutd}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita colo">{{data.imp_preciodeventa| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_utilidad| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.num_choferes}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.num_auxiliares}}
                                    </div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_sueldochofer| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_sueldoaux| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_combustible| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_comisioncombustible| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_urea| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_cel_gps| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_imss| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_hospedaje| currency}}</div>
                                </td>
                                <td>
                                    <div class="texto_negrita">{{data.imp_viaticos| currency}}</div>
                                </td>
                                <td>
                                    <button pButton icon="pi pi-save" styleClass="p-button-sm"
                                        style="margin-right: 10px !important; background-color: rgb(30, 155, 30); width: 52px; border: none;"
                                        pTooltip="Guardar Editar Tabulado" tooltipPosition="top"
                                        (click)="GuardarEditarTabulado(data);">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
                <ng-template #listViewTemplate>
                    <!-- Lista/columnas hacia abajo -->
                    <div class="row mt-3 mx-0 px-1 justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-12 mb-3" *ngFor="let data of TablaRespuestaEditar">
                            <!-- Contenido de cada fila -->
                            <div class="card h-60">
                                <div class="card-body">
                                    <div class="col flex">
                                        <div class="col-md-12" style="font-size: 1.2rem;">
                                            <h4 class="card-title texto_negrita align-items-left">
                                                Configuración: <p class="texto_verde_oliva">{{data.configuracion}}</p>
                                            </h4>
                                            <div class="row">
                                                <!-- Columna izquierda -->
                                                <div class="col-md-6">
                                                    <p class="card-title texto_negrita">Numero de Corrida: <span
                                                            class="texto_naranja_ba">#</span>{{ data.id_corrida }}</p>
                                                    <p class="card-text texto_negrita">Origen: <span
                                                            class="texto_naranja_ba">{{ data.origen }}</span></p>
                                                    <p class="card-text texto_negrita">Destino: <span
                                                            class="texto_naranja_ba">{{ data.destino }}</span></p>
                                                    <p class="card-text texto_negrita">Número de Días: {{ data.num_dias
                                                        }}</p>
                                                    <p class="card-text texto_negrita">Costo: <span class="colo">{{
                                                            data.costo | currency }}</span></p>
                                                    <p class="card-text texto_negrita">Precio de Venta: <span
                                                            class="colo">{{ data.imp_preciodeventa |currency }}</span>
                                                    </p>
                                                    <p class="card-text texto_negrita">Utilidad: <span class="colo">{{
                                                            data.imp_utilidad | currency }}</span>&nbsp;<i
                                                            style="font-size: 1.3rem;"
                                                            class="pi pi-star texto_amarillo_mostaza"></i></p>
                                                    <p class="card-text texto_negrita">Porcentaje UTD: <span
                                                            class="texto_naranja_ba">%{{ data.num_porcentajeutd
                                                            }}</span></p>
                                                    <p class="card-text texto_negrita">Sueldo Chofer: <span
                                                            class="colo">{{ data.imp_sueldochofer |currency }}</span>
                                                    </p>
                                                </div>
                                                <!-- Columna derecha -->
                                                <div class="col-md-6">
                                                    <p class="card-text texto_negrita">Sueldo Auxiliar: <span
                                                            class="colo">{{ data.imp_sueldoaux |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Combustible: <span
                                                            class="colo">{{ data.imp_combustible |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Comisión Combustible: <span
                                                            class="colo">{{data.imp_comisioncombustible | currency
                                                            }}</span></p>
                                                    <p class="card-text texto_negrita">Urea: <span
                                                        class="colo">{{data.imp_urea | currency}}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Celular GPS: <span
                                                            class="colo">{{ data.imp_cel_gps |currency }}</span></p>
                                                    <p class="card-text texto_negrita">IMSS: <span class="colo">{{
                                                            data.imp_imss | currency }}</span></p>
                                                    <p class="card-text texto_negrita">Mantenimiento: <span
                                                            class="colo">{{ data.imp_mantenimiento | currency }}</span>
                                                    </p>
                                                    <p class="card-text texto_negrita">Depreciacion: <span
                                                            class="colo">{{data.imp_depreciacion | currency}}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Hospedaje: <span
                                                            class="colo">{{ data.imp_hospedaje |currency }}</span></p>
                                                    <p class="card-text texto_negrita">Gasto Viáticos: <span
                                                            class="colo">{{ data.imp_viaticos |currency }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col flex align-items-center justify-content-center mt-3">
                                        <img *ngIf="data.configuracion==='LV'" src="../../../assets/images/van.png"
                                            alt="Imagen" class="img-fluid" pTooltip="LV">
                                        <img *ngIf="data.configuracion==='3.5'" src="../../../assets/images/3.5.png"
                                            alt="Imagen" class="img-fluid" pTooltip="3.5">
                                        <img *ngIf="data.configuracion==='RABON'" src="../../../assets/images/rabon.png"
                                            alt="Imagen" class="img-fluid" pTooltip="RABON">
                                        <img *ngIf="data.configuracion==='THORTON'"
                                            src="../../../assets/images/rabon.png" alt="Imagen" class="img-fluid"
                                            pTooltip="THORTON">
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button pButton label="Guardar Tabulado" icon="pi pi-save" styleClass="p-button-sm"
                                        style="background-color: rgb(30, 155, 30); border: none;"
                                        pTooltip="Guardar Tabulado" tooltipPosition="top"
                                        (click)="GuardarEditarTabulado(data);">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="align-items-center mt-4">
                    <div class="d-flex justify-content-around">
                        <button class="btn btn-info" pButton icon="pi pi-undo" styleClass="p-button-sm"
                            pTooltip="Nuevo Tabulado" label="Generar Nuevo Tabulado"
                            style="margin-right: 10px !important;width: 52px; width: 18rem;" tooltipPosition="top"
                            (click)="GenerarNuevoTabulado();">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaEditarModal()"></button>
        </ng-template>
    </p-dialog>