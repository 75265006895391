<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-share-alt mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 11%; top: 0%;">Clasificacion</header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <button type="button" class="btn btn-primary w-100" (click)="redireccionarNueva(1);"
                                    pTooltip="Nuena Clasificacion" tooltipPosition="top">
                                    Agregar Clasificacion<i class="pi pi-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="clasificacion" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped" 
                                dataKey="id_clasificacion_de_cte" [rows]="10"
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
                                [paginator]="true"
                                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                                [globalFilterFields]="['nom_clasificacion','des_clasificacion']">
                                <ng-template pTemplate="caption">
                                    <div class="row flex justify-content-between bordes">
                                        <div class="col-md-3">
                                            <button pButton label="Clear" class="p-button-outlined"
                                            icon="pi pi-filter-slash" (click)="clear(dt1)">
                                            </button>
                                        </div>
                                        <div class="col-md-3">
                                            <p-columnFilter field="nom_clasificacion" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="clasificacion"
                                                        optionLabel="nom_clasificacion" optionValue="nom_clasificacion"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por Nombre" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-3 estilo">
                                            <p-columnFilter field="clv_activo" class="custom-column-filter" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="objEstatus"
                                                        optionLabel="des_estatus" optionValue="clv_activo"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por estatus" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-3">
                                            <span class="ml-auto">
                                                <i class="pi pi-search mover"></i>
                                                <input pInputText type="text"
                                                    (input)="applyFilterGlobal($event, 'contains')"
                                                    placeholder="Buscar Palabra" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th style="min-width:15rem">
                                            <div class="flex align-items-center">
                                                Nombre del cliente
                                                <p-columnFilter type="text" field="nombreClasificacion"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem">
                                            <div class="flex align-items-center">
                                                descripcion
                                                <p-columnFilter type="text" field="descripcion"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th style="min-width:10rem">
                                            <div class="flex align-items-center">
                                                estatus
                                                <p-columnFilter type="text" field="clv_activo"
                                                    display="menu"></p-columnFilter>
                                            </div>
                                        </th>
                                        <th>
                                            
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex">
                                    <tr [@nuevoRegistro] [ngClass]="{'row-accessories': data.clv_activo ===0}">
                                        <td>
                                            {{(index+1)}}
                                        </td>
                                        <td>
                                         {{data.nom_clasificacion | uppercase }}
                                        </td>
                                        <td>
                                            {{data.des_clasificacion | uppercase }}
                                        </td>
                                        <td>
                                            <p-tag *ngIf="data.clv_activo==1" icon="pi pi-user" [rounded]="true" severity='success' value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" icon="pi pi-user" [rounded]="true" severity='warning' value="Desactivado"></p-tag>
                                            <!--p-tag *ngif="clienteProspecto.clv_Estatus==2"  [value]="clienteProspecto.des_Estatus" severity='danger'></!--p-tag-->
                                        </td>
                                        <td style="width: 130px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data,2);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus"
                                                    tooltipPosition="top" (click)="confirmarDesactivar(data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="7">Sin clasificaciones.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="row mt-3 d-flex justify-content-center">
                                <div class="col-6">
                                    <div class="mt-3 mb-2">
                                        <p-colorPicker [(ngModel)]="color_gris_deshabilitadas"
                                            [disabled]="true"></p-colorPicker>
                                        <p class="text-left informativo" style="display: inline;">
                                            clasificaciones desactivadas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspClasificacion"
        [style]="{width: '70vw','height':'600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
            Agregar Clasificacion
            <!--p>{{ texto_operacion}}</!--p-->
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-5">
                <div class="card-content card-shadow sinborde">
                    <form class="form_cliente" [formGroup]="clasificacionForm" (ngSubmit)="guardarInformacion()">
                        <div class="ml-1 px-1">
                            <span class="title-light">clasificacion<i class="pi pi-cog"
                                    style="size: 2rem;"></i></span>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control sinborde" placeholder="Nombre clasificacion*"
                                                formControlName="nombreClasificacion" style="text-transform:uppercase;"
                                                pTooltip="Nombre" tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-1 mx-0">
                                            <span class="input-group-addon"> <i class="pi pi-book color-icon"
                                                    style="font-size: 2rem"></i>
                                            </span>
                                        </div>
                                        <div class="col-sm-11" pTooltip="Descripcion" tooltipPosition="top">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="ingresar descripcion" formControlName="descripcion"
                                                style="text-transform:uppercase;">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <p *ngIf="!clasificacionForm.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                            La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="clasificacionForm.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                            Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        pTooltip="Almacenar informacion" tooltipPosition="right">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>
</div>