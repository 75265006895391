<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<p-toast position="bottom-center" [style]="{width: '20vw'}" key="confirm" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start" style="flex: 1">
            <div class="flex align-items-center gap-2">
                <p-avatar image="../../../assets/images/notificacionbonita.png" size="xlarge" shape="circle"
                    class="avatar-grande"></p-avatar>
                <span class="font-bold text-900">Recordatorio</span>
            </div>
            <div class="font-medium text-lg my-3 text-900">{{ message.summary }}</div>
            <p-button class="p-button-sm" label="Aceptar"></p-button>
        </div>
    </ng-template>
</p-toast>

<div class='demo-app'>
    <div class='demo-app-sidebar' style="font-size: 1rem;">
        <div class='demo-app-sidebar-section'>
            <h2><b>Instrucciones</b></h2>
            <ul>
                <li>Selecciona la fecha donde quieras crear un nuevo evento</li>
                <li>Arrastra y mueve los eventos para modificar su fecha de inicio</li>
            </ul>
        </div>
        <div class='demo-app-sidebar-section'>
            <label>
                <input type='checkbox' style="transform: scale(1.5);" [checked]='calendarVisible' [checked]='dspAgenda'
                    (change)='handleCalendarToggle()' />
                Mostrar Agenda
            </label>
        </div>
        <div class='demo-app-sidebar-section'>
            <label>
                <input type='checkbox' style="transform: scale(1.5);" [checked]='calendarOptions.weekends' (change)='handleWeekendsToggle()' />
                Quitar Fines de semana
            </label>
        </div>
        <div class='demo-app-sidebar-section'>
            <h2>Todos los eventos ({{currentEvents.length}})</h2>
            <ul>
                <li *ngFor='let event of currentEvents'>
                    <b>{{event.startStr}}</b>
                    <i>{{event.title}}</i>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf='calendarVisible' class='demo-app-main'>
        <div class="row d-flex justify-content-center bajar">
            <div class="col-2">
                <button type="button" class="btn btn-primary w-100" pTooltip="Nuevo Evento" tooltipPosition="top"
                    (click)="AgregarEvento(1)">
                    Agregar Evento<i class="pi pi-plus-circle"></i>
                </button>
            </div>
        </div>
        <full-calendar #fullcalendar [options]='calendarOptions'>
            <ng-template #eventContent let-arg>
                <div class="contenedor" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                    <div *ngIf="arg.event.extendedProps.clv_status==0" style="background-color: lightgray;">
                        &nbsp;
                        <i class="pi pi-clone color" (click)="VerTarea(arg.event)" style="margin-top: 3px;"
                            pTooltip="Ver informacion">&nbsp;</i>
                        <b (click)="EstatusTarea(arg.event)">{{ arg.timeText }}</b>
                        <i (click)="EstatusTarea(arg.event)" pTooltip="Tarea Sin Finalizar">{{ arg.event.title }}</i>
                    </div>
                    <div *ngIf="arg.event.extendedProps.clv_status==1" style="background-color: orange;">
                        &nbsp;
                        <i class="pi pi-clone" (click)="VerTarea(arg.event)" pTooltip="Ver informacion"
                            style="margin-top: 3px;">&nbsp;</i>
                        <b (click)="EstatusTarea(arg.event)" pTooltip="Tarea Activa">{{ arg.timeText }}</b>
                        <i (click)="EstatusTarea(arg.event)" pTooltip="Tarea Activa">{{ arg.event.title }}</i>
                    </div>
                    <div *ngIf="arg.event.extendedProps.clv_status==2" (click)="EstatusTarea(arg.event)"
                        style="background-color: lightgreen;">
                        &nbsp;
                        <i class="pi pi-clone" style="margin-top: 3px;">&nbsp;</i>
                        <b>{{ arg.timeText }}</b>
                        <i pTooltip="Tarea Finalizada">{{ arg.event.title }}</i>
                    </div>
                </div>
            </ng-template>
        </full-calendar>
    </div>


    <p-dialog [(visible)]="CardVisible" *ngFor="let campo of CardCalendario" [draggable]="false" [modal]="true"
        [style]="{width:'60vh'}" styleClass="white-dialog">
        <ng-template pTemplate="header">
            <div class="dialog-header-custom">
                <img style="max-width: 900px; height: 65px;" src="../../../assets/images/logo_centrado.png" alt="jappi">
            </div>
        </ng-template>
        <p-card [resizable]="false" [draggable]="false" [style]="{ width: '70hv' }">
            <ng-template pTemplate="header">
                <div class="d-flex justify-content-center" style="margin-top: 10px;">
                    <img alt="Card" style="max-width: 600px; height: auto;" src="../../../assets/images/usercard.png" />
                </div>
                <div class="d-flex justify-content-around" style="margin-top: 25px;">
                    <span style="font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 0.5rem; color:rgb(67, 142, 55); align-items: center;"><i class="pi pi-comment"
                            pTooltip="Titulo de la tarea:"
                            style="color: #495057; font-size: 25px; font-weight: bolder;"></i> {{ campo.title }}</span>
                </div>
            </ng-template>
            <p-subheader *ngIf="campo.nom_empresa === ''; else clienteVacioSubheader">
                <h6><b>Recordatorio:</b>Esta es una tarea propia por lo cual te sugerimos te tomes tu tiempo para poder
                    realizarla.</h6>
            </p-subheader>
            <ng-template #clienteVacioSubheader>
                <p-subheader style="text-align: justify;">
                    <h5>Se Contactará al Cliente: <b>{{ campo.nom_empresa|uppercase }}</b> por Medio de <b>{{
                            campo.mediocontacto }}</b></h5>
                </p-subheader>
            </ng-template>
            <ng-container [style]="{'margin-top':'30px'}">
                <div class="container-fluid" style="margin-top: 30px;">
                    <div class="form-card">
                        <p>
                        <h3><i class="pi pi-calendar" style="font-size: 26px;color: blue;"></i> Fecha:</h3>
                        <h5>{{campo.start}}</h5>
                        <hr class="my-0">
                        <p>
                        <h3><i class="pi pi-pencil" style="font-size: 26px;color: blue;"></i> Notas:</h3>
                        <h5>{{campo.notas}}</h5>
                        <hr class="my-0">
                    </div>
                </div>
            </ng-container>
            <ng-template pTemplate="footer">
                <p-button label="Aceptar" (click)="CardVisible=false" icon="pi pi-check"></p-button>
            </ng-template>
        </p-card>
    </p-dialog>

    <!---->
    
    <div class="card-content" *ngIf='!calendarVisible'>
        <div class="row mt-3 mx-0 px-1">
            <p-table #dt1 [value]="catAgenda" [tableStyle]="{'min-width': '50rem'}"
                styleClass="p-datatable-sm p-datatable-striped" dataKey="id" responsiveLayout="scroll"
                [scrollable]="true" scrollDirection="both" scrollHeight="580px" [paginator]="true" [rows]="10"
                [rowsPerPageOptions]="[10, 25, 50,100]" [loading]="loading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                [globalFilterFields]="['nom_empresa','des_correocontacto','estado','des_nombre','des_apellidopaterno','nom_puesto','nom_clasificacionpuesto','num_telefono']">
                <ng-template pTemplate="header">
                    <!--div class="card card-shadow sinborde" style="width: 15vh;">
                        <div style=" justify-content: center; align-items: center; height: 100%;">
                            <p-header style="font-size: 2.5rem; text-align: center;">
                                <p class="texto_negrita"><i class="fas fa-address-book color-icon"></i>Agenda</p>
                            </p-header>
                        </div>
                    </!--div-->
                    <tr [ngClass]="{'texto_sm':true}">
                        <th pFrozenColumn style="min-width:5rem;width: 5rem;">
                            #
                        </th>
                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Nombre de la Empresa
                                <p-columnFilter type="text" field="nom_empresa" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Correo
                                <p-columnFilter type="text" field="des_correocontacto" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th pFrozenColumn style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Estado
                                <p-columnFilter type="text" field="estado" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width:15rem;width: 15rem;" pSortableColumn="des_nombre">
                            <div class="flex align-items-center">
                                Nombre del Contacto
                                <p-sortIcon field="des_nombre"></p-sortIcon>
                            </div>
                        </th>
                        <th style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Apellido Paterno
                                <p-columnFilter type="text" field="des_apellidopaterno" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Puesto
                                <p-columnFilter type="text" field="nom_puesto" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width:10rem;width: 10rem;">
                            <div class="flex align-items-center">
                                Area a la que Pertence
                                <p-columnFilter type="text" field="nom_clasificacionpuesto"
                                    display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width:15rem;width: 15rem;" pSortableColumn="num_telefono">
                            <div class="flex align-items-center">
                                Numero de Telefono
                                <p-sortIcon field="num_telefono"></p-sortIcon>
                            </div>
                        </th>
                        <th colspan="2">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index="rowIndex" let-editing="editing">
                    <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">
                        <td pFrozenColumn [frozen]="true" class="texto_negrita">
                            {{(index+1)}}
                        </td>
                        <td pFrozenColumn [frozen]="true" class="texto_negrita texto_naranja_ba">
                            <div> {{data.nom_empresa | uppercase}}</div>
                        </td>
                        <td pFrozenColumn [frozen]="true">
                            <i *ngIf="data.correo!==''" class="pi pi-envelope mt-3"
                                style="font-size: 1rem; color:rgb(132, 119, 38);"></i>
                            {{data.des_correocontacto | uppercase}}
                        </td>
                        <td pFrozenColumn [frozen]="true">

                            <div class="texto_negrita texto_verde_oliva"><i class="pi pi-globe mt-3"
                                    style="font-size: 1rem; color:cornflowerblue;"></i> {{data.estado | uppercase}}
                            </div>
                        </td>
                        <td>
                            <div class="texto_negrita"><i class="pi pi-user mt-3"
                                    style="font-size: 1rem; color:rgb(30, 35, 46);"></i> {{data.des_nombre | uppercase}}
                            </div>
                        </td>
                        <td>
                            <div class="texto_negrita">{{data.des_apellidopaterno | uppercase}}</div>

                        </td>
                        <td>
                            <div>{{data.nom_puesto | uppercase}}</div>
                        </td>
                        <td>
                            <div>{{data.nom_clasificacionpuesto | uppercase}}</div>
                        </td>
                        <td>
                            <i class="pi pi-phone mt-3" style="font-size: 1rem; color:cornflowerblue;"></i>
                            {{data.num_telefono}}
                        </td>
                        <div class="justify-content-center">
                            <button class="btn btn-info" pButton icon="pi pi-plus" styleClass="p-button-sm"
                                pTooltip="Agregar Tarea" style="margin-right: 10px !important;width: 52px;"
                                tooltipPosition="top" (click)="AgregarEventoAgenda(1,data)">
                            </button>
                        </div>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Sin Agenda.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <!--p-dialog header="Detalles de la tarea" [resizable]="false" [draggable]="false" styleClass="card-shadow"
     [(visible)]="displayDialog" [style]="{width: '50%',height:'350px'}">
        <ng-template pTemplate="content">
            <div class="form-card">
                <div *ngIf="tareaSeleccionada">
                    <p>{{ tareaSeleccionada.title }}</p>
                    <button pButton type="button" (click)="EstatusTarea(tareaSeleccionada)" label="Iniciar Tarea"></button>
                  </div>
            </div>
        </ng-template>
    </!--p-dialog-->

    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspAgregarEvento" [modal]="true"
        [style]="{width: '50vw','height':'700px','background':'#fff222'}" [transitionOptions]="'0ms'"
        styleClass="card-shadow" (onHide)="cancelarVentanaModal()">
        <ng-template pTemplate="header" style="background: #fff222;">
            Nueva Tarea
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-4">
                <div class="card-content card-shadow sinborde">
                    <form class="form_cliente" [formGroup]="calendarioForm" (ngSubmit)="guardarEvento()">
                        <div class="ml-1 px-1">
                            <div class="row mt-5">
                                <div class="form-group col-md-10">
                                    <div class="row g-0">
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control sinborde"
                                                placeholder="Ingresa tu tarea" formControlName="titulo"
                                                style="text-transform:uppercase;" pTooltip="Ingresa una tarea"
                                                tooltipPosition="right">
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span>Notificarme* </span>
                                            <p-checkbox pTooltip="click para activar la notificacion" [binary]="true"
                                                (click)="MostrarDrop()"></p-checkbox>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 pl-2">
                                            <p-dropdown [disabled]="check" [options]="notificaciones"
                                                optionLabel="des_notificacion" formControlName="tiempo_recordatorio"
                                                autoWidth="false" [style]="{'width':'100%'}"
                                                optionValue="id_notificacion" [showClear]="true"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span>Seleccione si la tarea sera asignada a un cliente* </span>
                                            <p-checkbox formControlName="chekk"
                                                pTooltip="Asigne para quien sera la tarea" [binary]="true"
                                                (click)="MostrarClientes()"></p-checkbox>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 pl-2">
                                            <p-dropdown [disabled]="checked" [(ngModel)]="Ctepros"
                                                [options]="catClientesProspecto" optionLabel="nom_empresa"
                                                formControlName="id_clienteProspecto" autoWidth="false"
                                                [style]="{'width':'100%'}" optionValue="id_cte_prospecto"
                                                [showClear]="true" tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span>Medio por el cual se contactara al Cliente* </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 pl-2">
                                            <p-dropdown [disabled]="checked" [options]="cat_medioDeContacto"
                                                optionLabel="nom_mediosdecontacto" formControlName="medioDeContacto"
                                                autoWidth="false" [style]="{'width':'100%'}" placeholder="Seleccione el Medio de Cto"
                                                optionValue="nom_mediosdecontacto" [showClear]="true"
                                                tooltipPosition="left"></p-dropdown>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <div>
                                        <span>Asignar a: </span>
                                    </div>
                                    <div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-sm-6 pl-2">
                                            <p-multiSelect [options]="cat_usuariosComercial"
                                                [(ngModel)]="usuariosSeleccionados"
                                                defaultLabel="Selecciona uno o varios usuarios" autoWidth="false"
                                                optionValue="cod_usuario" formControlName="nom_empleado"
                                                [showClear]="true" optionLabel="nom_empleado" [appendTo]="'body'"
                                                display="chip"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span>Fecha Inicio</span><i class="pi pi-calendar color-icon d-inline"
                                                style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p-calendar #calendar [appendTo]="'body'" [(ngModel)]="fechaYHora"
                                                formControlName="fecha_inicio" [showTime]="showSeconds"
                                                [showSeconds]="false" [style]="{'width':'100%'}"></p-calendar>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <span>Fecha Fin</span><i class="pi pi-calendar color-icon"
                                                style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p-calendar #calendar2 [(ngModel)]="fechaYHoraFin" [appendTo]="'body'"
                                                formControlName="fecha_fin" [showTime]="showSeconds"
                                                [showSeconds]="false" [style]="{'width':'100%'}"></p-calendar>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div>
                                    <span>Notas </span><i class="pi pi-book color-icon" style="font-size: 2rem"></i>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="row g-0">
                                        <div class="col-sm-11" pTooltip="notas" tooltipPosition="top">
                                            <textarea rows="5" cols="70" pInputTextarea
                                                formControlName="notas"></textarea>
                                            <hr class="my-0">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row d-flex justify-content-center mt-3">
                                <div class="col-4">
                                    <p *ngIf="!calendarioForm.valid" class="texto-invalido text-right">
                                        <i class="pi pi-info-circle" style="font-size: 1rem;">Es Necesario Proporcionar
                                            La Informaci&oacute;n Obligatoria(*)</i>
                                    </p>
                                    <p *ngIf="calendarioForm.valid" class="texto-valido text-right">
                                        <i class="pi pi-check-circle" style="font-size: 1rem;">Informaci&oacute;n
                                            Completa</i>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary form-control" type="submit"
                                        pTooltip="Almacenar informacion" tooltipPosition="right"
                                        [disabled]="!calendarioForm.valid">
                                        <span class="text-white">Guardar</span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="cancelarVentanaModal()"></button>
        </ng-template>
    </p-dialog>
</div>