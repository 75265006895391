import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";
//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import MediosDeContactoModel from '../models/MediosDeContactoModel';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl: string = "";
  private usuariosSubject = new BehaviorSubject<any[]>([]);
  private apiUrlComercial:string="";

  constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
    this.apiUrl = environment.sUrlAuth + 'Auth';
    this.apiUrlComercial=environment.SUrlgApi+'MContacto';
   }

  public async post(method: string, payload: any = {}): Promise<any> {
    if (!this.auth.validateToken()) this.router.navigate(['/unauthorized']);
    return new Promise<any>((resolve, reject) => {
      const headers = new HttpHeaders();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${this.auth.token}`);
      this.http.post(`${this.apiUrl}${method}`, payload, { headers }).pipe(
        take(1),
      )
        .subscribe(res => {
          resolve(res); //PENDIENTE
        },
          error => {
            console.log(error);
            this.message.add({ severity: "error", summary: "Error al consultar", detail: error.message, life: 5000 });
            reject(error); //PENDIENTE
          });
    });
  }

  public async get(method: string): Promise<any> {
    if (!this.auth.validateToken()) this.router.navigate(['/unauthorized']);
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiUrl}${method}`).pipe(
        take(1),
      )
        .subscribe(res => {
          resolve(res); //PENDIENTE
        },
          error => {
            console.log(error);
            this.message.add({ severity: "error", summary: "Error al consultar", detail: error.message, life: 5000 });
            reject(error); //PENDIENTE
          });
    });
  }

  public async login(cookie: string): Promise<boolean> {
    alert('entro aki');
    let Cookie:string=cookie.replace("Bearer ","");
    Cookie.trim();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.apiUrl}/LoginUrl`, { Cookie }).pipe(take(1)).subscribe(
        async (res: any) => {
          if (res['token']) {
            this.auth.setToken(res['token']);
            await this.getPermissions().then((permissions: Module[]) => {
              this.auth.permissions = permissions;
            });
            resolve(true);
          } else {
            this.auth.setToken(null);
            resolve(false);
          }
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al iniciar sesión", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }

  public async loginWithEmailNPassword(user: any): Promise<boolean> {
    this.auth.clearCredentials();
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.apiUrl}/Login`, user).pipe(take(1)).subscribe(
        async (res: any) => {
          if (res['token']) {
            this.auth.setToken(res['token']);
            this.router.navigateByUrl("/");
            await this.getPermissions().then((permissions: Module[]) => {
              this.auth.permissions = permissions;
            });
            resolve(true);
          } else {
            this.auth.setToken(null);
            resolve(false);
          }
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al iniciar sesión", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado. Inicie Sesión.", life: 10000 });
          this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }
//Medios de contacto
  public SaveMedioContacto(data:MediosDeContactoModel):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrlComercial + '/guardarMedioContacto/',body,{'headers':headers});
  }

  public getMedioContacto():Observable<MediosDeContactoModel[]>{
    return this.http.get<MediosDeContactoModel[]>(this.apiUrlComercial).pipe(retry(1), catchError(this.handleError));
  }

  public editMedioContacto(data: MediosDeContactoModel ):Observable<any>{
    const headers = { 'content-type': 'application/json'};
    const body = JSON.stringify(data); 
    return this.http.put<any>(this.apiUrlComercial + '/editMedioContacto/',body,{'headers':headers});
   }
//Fin medios de contacto

  /*public AgregarRegistro(Registro:any) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
       'Authorization': `Bearer ${this.auth.token}`
     });
    return this.http.post(`${this.apiUrl}/Registrar`,Registro,{ headers: httpHeaders });
 }*/

/*public ObtenerRegistros():void{
  const httpHeaders: HttpHeaders = new HttpHeaders({
    'Authorization': `Bearer ${this.auth.token}`
  });
  this.http.get<any[]>(`${this.apiUrl}/ObtenerRegistros`,{ headers: httpHeaders }).subscribe(
    (usuarios) => {
      this.usuariosSubject.next(usuarios);
    },
    (error) => {
      console.error('Error al obtener usuarios:', error);
    }
  );
}*/

getUsuarios(): Observable<any[]> {
  return this.usuariosSubject.asObservable();
}

  public async getPermissions(): Promise<Array<Module>> {
    return new Promise<Array<Module>>((resolve, reject) => {
      const httpHeaders: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${this.auth.token}`
      });
      this.http.post(`${this.apiUrl}/AccesoModulos`, { Clave_Sistema: 'SDC' }, { headers: httpHeaders }).pipe(take(1)).subscribe(
        (data: any) => {

          resolve(groupBy(data as Array<any>, 'nom_Modulo', permissionMapper));
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al obtener los permisos de usuario", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado. Inicie Sesión.", life: 10000 });
          this.router.navigateByUrl("/login");
          //this.auth.setToken(null);
          resolve([]);
        }
      )
    });
  }

  public async hasPermission(optionId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const httpHeaders: HttpHeaders = new HttpHeaders({
        'Authorization': `Bearer ${this.auth.token}`
      });
      this.http.post(`${this.apiUrl}/PermisoModulos`, { Cod_Opcion: optionId }, { headers: httpHeaders }).pipe(take(1)).subscribe(
        (data: any) => {
          resolve(data);
        },
        err => {
          this.message.add({ severity: "error", summary: "Error al obtener los permisos de usuario", detail: "No cuentas con permisos de acceso para este sistema o tu sesión ha caducado", life: 10000 });
          //this.auth.setToken(null);
          resolve(false);
        }
      )
    });
  }
  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }

}
