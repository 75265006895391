import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import User from 'src/app/shared/models/user';
import { MessageService,ConfirmationService,ConfirmEventType } from 'primeng/api';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import TipoRuta from 'src/app/shared/models/TipoRuta';
import { tiporuta } from 'src/app/shared/services/tiporuta.service';


@Component({
  selector: 'app-tipo-de-ruta',
  templateUrl: './tipo-de-ruta.component.html',
  styleUrls: ['./tipo-de-ruta.component.scss']
})
export class TipoDeRutaComponent {
  usuario:User={};
  color_gris_deshabilitadas:string = '#D9D9D9';
  TipoRutaObj:TipoRuta[]=[];
  /*tipoclasificacion:TipoClasificacion[]=[{
    tipoClasificacion:'mayorista'
  },
  {
    tipoClasificacion:'minorista'
  }]*/

  @ViewChild('dt1') dt!: Table;
  dspTipoRuta:boolean=false;
  activityValues: number[] = [0, 100];
  loading: boolean = true;
  TipoRutaForm: FormGroup = new FormGroup({
    NumeroClasificacion: new FormControl(0,[]),
    Nombre: new FormControl('', [ 
      Validators.required
    ]),
    clv_activo: new FormControl(0, [])
  });
  fecha_dia:any;
  numero_operacion:number=0;
  texto_operacion: string = '';

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
                                                                     
  data_editar_TipoRuta: TipoRuta={
    id_clasificacion:0,
    cod_usuario: '',
    des_nombre:'',
    clv_activo:0,
    opcion:0
  };

  constructor(private httpService: HttpService, private TipoRutaService:tiporuta,private message:MessageService,private auth:AuthService,
    private confirmationService: ConfirmationService) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.obtenerTipoRuta();
    this.inicializarFechas();
  }

  obtenerTipoRuta(){
    this.loading=true;
    this.TipoRutaService.getTipoRuta().subscribe((resp)=>{
      this.TipoRutaObj=resp;
      setTimeout(() => {
        this.loading=false;
      }, 1000);
    })
  }

  redireccionarNueva(num_operacion:number) {
    this.numero_operacion=num_operacion;
    this.texto_operacion = '';
    this.TipoRutaForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.texto_operacion = 'Nueva Ruta';
    //
    //
    this.dspTipoRuta = true;
}


  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  confirmarDesactivar(data: TipoRuta) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> la ruta <b>'+data.des_nombre+'</b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        data.opcion=3;
        data.cod_usuario = this.usuario.cod;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: TipoRuta) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    //data.cod_usuario_ult_mov=this.usuario.cod==null? '0999': this.usuario.cod;
    this.TipoRutaService.editTipoRuta(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado.clv_estatus==1){
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.TipoRutaForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
    (error)=> {
      this.message.add({ severity: 'error', summary: 'Error en <Editar Ruta>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
    //Formatear fecha de Inicio
  }
  cancelarVentanaModal() {
    this.texto_operacion = '';
    this.dspTipoRuta = false;
    this.TipoRutaForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  mostrarModalEditar(data: TipoRuta,num_operacion:number) {
    this.data_editar_TipoRuta=data;
    this.numero_operacion=num_operacion;
    this.TipoRutaForm.controls['Nombre'].setValue(data.des_nombre);
    this.TipoRutaForm.controls['NumeroClasificacion'].setValue(data.id_clasificacion);
    this.dspTipoRuta = true;
    this.texto_operacion = 'Editar Nombre Ruta';
  }

  guardarInformacion(){
    this.inicializarFechas();
    let data_enviar: TipoRuta={
      id_clasificacion:this.numero_operacion==1 ? 0:this.TipoRutaForm.get('NumeroClasificacion')?.value,
      clv_activo: typeof this.TipoRutaForm.get('clv_activo')?.value === 'object' ? 1 : this.TipoRutaForm.get('clv_activo')?.value,
      des_nombre: this.TipoRutaForm.get('Nombre')?.value?.toUpperCase(),
      cod_usuario: this.usuario.cod ,
      opcion:this.numero_operacion
    };
    if(this.numero_operacion==1){
      this.TipoRutaService.SaveTipoRuta(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(()=>{
          this.obtenerTipoRuta();
          this.message.add({ severity: 'success', summary: 'Tipo De Ruta Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.TipoRutaForm.reset();
          this.cancelarVentanaModal();
        },800);
      },
      (error)=> {
        this.message.add({ severity: 'error', summary: 'Error en <Tipo De Ruta>', detail: 'Contacte al Administrador del Sitio' });
      });
    } else
    if(this.numero_operacion==2){
      this.TipoRutaService.editTipoRuta(data_enviar).subscribe((resp)=>{
        var resultado=resp;
        setTimeout(() => {
          this.obtenerTipoRuta();
          this.message.add({severity:'success',summary:'Edicion Exitosa',detail:'la informacion se edito correctamente'});
          this.TipoRutaForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      })
    }
    
  }
  
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  clear(table: Table) {
    table.clear();

}
}
