import { Component, OnInit } from '@angular/core';
import BaseDeCobros from 'src/app/shared/models/BaseDeCobros';
import { basedecobros } from 'src/app/shared/services/basdecobros.service'; 
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import User from 'src/app/shared/models/user';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import IExportarBase from 'src/app/shared/models/exportarBaseDeCobros';

@Component({
  selector: 'app-base-de-cobros',
  templateUrl: './base-de-cobros.component.html',
  styleUrls: ['./base-de-cobros.component.scss']
})
export class BaseDeCobrosComponent implements OnInit {
  basedecobros: BaseDeCobros[] = [];
  tablaExportar: IExportarBase[] = [];
  usuario:User={};
  fecha_dia:any;
  numero_operacion:number=0;
  color_gris_deshabilitadas:string = '#D9D9D9';
  dspbasedecobros:boolean=false;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  baseDeCobrosForm: FormGroup = new FormGroup({
    id_config_unidad: new FormControl('', [Validators.required]),
    des_tipoconfiguracionunidad: new FormControl('', [Validators.required]),
    km_inicial: new FormControl(0, []),
    km_final: new FormControl(0, []),
    imp_sueldodriver: new FormControl(0, []),
    imp_sueldoaux: new FormControl(0, []),
    imp_prestacionesrhdiario: new FormControl(0, []),
    imp_mantenimientodiario: new FormControl(0, []),
    imp_monitoreodiario: new FormControl(0, []),
    imp_operaciones: new FormControl(0, []),
    imp_depreciacion: new FormControl(0, []),
    clv_activo: new FormControl(0, []),
    idx: new FormControl(0, [])
});
  data_editar_basedecobros: BaseDeCobros={
    opcion:0,
    cod_usuario:'',
    idx:0,
    id_config_unidad:0,
    des_tipoconfiguracionunidad:'',
    km_inicial:0,
    km_final:0,
    imp_sueldodriver:0,
    imp_sueldoaux:0,
    imp_prestacionesrhdiario:0,
    imp_mantenimientodiario:0,
    imp_monitoreodiario:0,
    imp_operaciones:0,
    imp_depreciacion:0,
    clv_activo:0,
  };

  constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private basedecobrosService:basedecobros){
    this.cargarInformacionUsuario();
  }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  ngOnInit(): void {
    this.inicializarFechas();
    this.GetBaseDeCobros();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
  }

  GetBaseDeCobros(): void {
    this.basedecobrosService.getbasedecobros().subscribe({
      next: (data) => {
        this.basedecobros = data; 
      },
      error: (err) => {
        console.error('Error al cargar los datos de base de cobros', err); 
      }
    });
  }

  mostrarModalEditar(data: BaseDeCobros, num_operacion: number) {
    this.dspbasedecobros = true; // Muestra el diálogo.
    this.data_editar_basedecobros = data; // Asigna los datos de la fila seleccionada.
    this.numero_operacion = num_operacion;
    
    // Llenar el formulario con los datos a editar.
    this.baseDeCobrosForm.patchValue({
        idx: data.idx,
        id_config_unidad: data.id_config_unidad,
        des_tipoconfiguracionunidad: data.des_tipoconfiguracionunidad,
        km_inicial: data.km_inicial,
        km_final: data.km_final,
        imp_sueldodriver: data.imp_sueldodriver,
        imp_sueldoaux: data.imp_sueldoaux,
        imp_prestacionesrhdiario: data.imp_prestacionesrhdiario,
        imp_mantenimientodiario: data.imp_mantenimientodiario,
        imp_monitoreodiario: data.imp_monitoreodiario,
        imp_operaciones: data.imp_operaciones,
        imp_depreciacion: data.imp_depreciacion,
        clv_activo: data.clv_activo
    });

    // Cambiar el texto de la operación.
    this.texto_operacion = ' Editar Base de Cobros #' + data.idx ;
}


  confirmarDesactivar(data: BaseDeCobros) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> la configuración <b>'+data.des_tipoconfiguracionunidad+' </b>?',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.cod_usuario=this.usuario.cod;
        data.opcion=2;
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }

  guardarInformacionDesactivar(data: BaseDeCobros) {
    this.basedecobrosService.editbasedecobros(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado.clv_estatus==1){
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.baseDeCobrosForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
    (error)=> {
      this.message.add({ severity: 'error', summary: 'Error en <Editar Base de Cobros>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacion() {
    this.inicializarFechas();
    const data_enviar: BaseDeCobros = {
        idx: this.baseDeCobrosForm.get('idx')?.value,
        des_tipoconfiguracionunidad: this.baseDeCobrosForm.get('des_tipoconfiguracionunidad')?.value,
        clv_activo: typeof this.baseDeCobrosForm.get('clv_activo')?.value === 'object' ? 1 : this.baseDeCobrosForm.get('clv_activo')?.value,
        cod_usuario: this.usuario.cod,
        opcion: this.numero_operacion,
        id_config_unidad: this.baseDeCobrosForm.get('id_config_unidad')?.value, 
        km_inicial: this.baseDeCobrosForm.get('km_inicial')?.value, 
        km_final: this.baseDeCobrosForm.get('km_final')?.value, 
        imp_sueldodriver: this.baseDeCobrosForm.get('imp_sueldodriver')?.value, 
        imp_sueldoaux: this.baseDeCobrosForm.get('imp_sueldoaux')?.value, 
        imp_prestacionesrhdiario: this.baseDeCobrosForm.get('imp_prestacionesrhdiario')?.value, 
        imp_mantenimientodiario: this.baseDeCobrosForm.get('imp_mantenimientodiario')?.value, 
        imp_monitoreodiario: this.baseDeCobrosForm.get('imp_monitoreodiario')?.value,
        imp_operaciones: this.baseDeCobrosForm.get('imp_operaciones')?.value, 
        imp_depreciacion: this.baseDeCobrosForm.get('imp_depreciacion')?.value,
    };

    // Aquí no se compara nada, simplemente se envía data_enviar
    this.basedecobrosService.editbasedecobros(data_enviar).subscribe((resp) => {
        const resultado = resp;
        if (resultado.clv_estatus == 1) {
            setTimeout(() => {
                this.GetBaseDeCobros(); // Volver a cargar la lista de base de cobros
                this.message.add({ severity: 'success', summary: 'Operación Exitosa', detail: 'La información se almacenó correctamente' });
                this.baseDeCobrosForm.reset();
                this.cancelarVentanaModal();
            }, 800);
        }
    }, (error) => {
        this.message.add({ severity: 'error', summary: 'Error en <Guardar Base de Cobros>', detail: 'Contacte al Administrador del Sitio' });
    });
}
GenerarTodoAExcel() {
  if (this.basedecobros.length === 0) {
    return this.message.add({
      key: 'msj',
      severity: 'warn',
      summary: 'No Existen Tabulados',
      detail: 'No Existen Tabulados para Exportar'
    });
  }

  // Mapeamos la data a la estructura de IExportarBase
  this.tablaExportar = this.basedecobros.map((data) => {
    return {
      id_config_unidad: data.id_config_unidad,
      tipo_configuracion: data.des_tipoconfiguracionunidad,
      km_inicial: data.km_inicial,
      km_final: data.km_final,
      sueldo_driver: data.imp_sueldodriver,
      sueldo_auxiliar: data.imp_sueldoaux,
      prest_rh: data.imp_prestacionesrhdiario,
      mantenimiento: data.imp_mantenimientodiario,
      monitoreo: data.imp_monitoreodiario,
      operaciones: data.imp_operaciones,
      depreciacion: data.imp_depreciacion,
    };
  });

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tablaExportar);

  // Verificar si worksheet['!ref'] existe, si no, establecerlo
  if (!worksheet['!ref']) {
    worksheet['!ref'] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: this.tablaExportar.length - 1, c: Object.keys(this.tablaExportar[0]).length - 1 }
    });
  }
  // Ajustar el rango de celdas
  const range = XLSX.utils.decode_range(worksheet['!ref']!);
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

      // Inicializar celda si no existe
      if (!worksheet[cellRef]) {
        worksheet[cellRef] = { v: '', s: {} }; // Inicializar celdas vacías
      }
      
      // Aplicar estilos y formato
      if (row === 0) {
        // Encabezados
        worksheet[cellRef].s = {
          font: { bold: true, name: 'Arial', sz: 12 },
          alignment: { horizontal: 'center' },
        };
      } else {
        // Estilos para filas de datos
        const alignment = this.getAlignment(col);
        worksheet[cellRef].s = {
          font: { name: 'Arial', sz: 12 },
          alignment: { horizontal: alignment }
        };

        // Formato de moneda para columnas específicas
        if ([4, 5, 6, 7, 8, 9, 10].includes(col)) {
          worksheet[cellRef].z = '"$"#,##0.00'; // Formato de moneda en pesos mexicanos
        }
      }
    }
  }

  // Ajustar el ancho de las columnas
  worksheet['!cols'] = [
    { wpx: 20 }, // id_config_unidad
    { wpx: 80 }, // tipo_configuracion
    { wpx: 80 }, // km_inicial
    { wpx: 80 }, // km_final
    { wpx: 80 }, // sueldo_driver
    { wpx: 80 }, // sueldo_auxiliar
    { wpx: 80 }, // prest_rh
    { wpx: 80 }, // mantenimiento
    { wpx: 80 }, // monitoreo
    { wpx: 80 }, // operaciones
    { wpx: 80 }, // depreciacion
  ];

  const workbook: XLSX.WorkBook = {
    Sheets: { 'base de cobros': worksheet },
    SheetNames: ['base de cobros']
  };

  // Guardar el archivo
  const excelBuffer: any = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const fechaRegistro = new Date().toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-');

  this.saveExcelFile(excelBuffer, 'base_de_cobros_' + fechaRegistro);
}

private getAlignment(col: number): string {
  if (col === 0) return 'left';  // id_config_unidad a la izquierda
  if (col === 1) return 'center'; // tipo_configuracion al centro
  if (col === 2 || col === 3) return 'center'; // km_inicial y km_final al centro
  return 'right'; // resto de columnas a la derecha
}

private saveExcelFile(buffer: any, fileName: string): void {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  saveAs(data, `${fileName}.xlsx`);
}



  cancelarVentanaModal() {
    this.texto_operacion = '';
    //this.num_operacion=0;
    this.dspbasedecobros = false;
    this.baseDeCobrosForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }
}
